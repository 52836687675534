<template>
  <device-card flat
               :device="device"
               :title="$t('device-card-ir-controller.title').toString()"
               icon="tune">
    <sub-devices>
      <!-- mode switch -->
      <span style="position: relative;">
        <v-overlay v-if="loadingControllerMode" color="navBackground" absolute :value="loadingControllerMode" z-index="4" opacity="0.7">
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-overlay>
        <mode-switch :ir-controller="irController"
                     :loading="loadingControllerMode"
                     @set-controller-mode="setControllerMode"
                     :controller-modes="ControllerModes"/>
      </span>
      <v-divider></v-divider>
      <!-- temperature setpoint heating -->
      <v-list-item>
        <v-list-item-icon>
          <v-icon>thermostat</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="font-size-03">{{ $t('device-card-ir-controller.setpoint-heating') }}</v-list-item-title>
      </v-list-item>
      <mode-control-slider :has-step="false"
                           key="heating"
                           :loading="loadingHeatingSetpoint"
                           color="error"
                           @change-mode-value="(...args) => setModeValue(args, ControllerModes.HEAT)"
                           :received-value="setpointHeating"
                           :fan-modes="FanModes"
                           :ir-controller="irController"
                           :controller-modes="ControllerModes"
                           :disabled="irController.mode !== ControllerModes.HEAT"/>
      <v-divider></v-divider>
      <!-- temperature setpoint cooling -->
      <v-list-item>
        <v-list-item-icon>
          <v-icon>thermostat</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="font-size-03">{{ $t('device-card-ir-controller.setpoint-cooling') }}</v-list-item-title>
      </v-list-item>
      <mode-control-slider :has-step="false"
                           key="cooling"
                           :loading="loadingCoolingSetpoint"
                           color="info"
                           :received-value="setpointCooling"
                           :fan-modes="FanModes"
                           @change-mode-value="(...args) => setModeValue(args, ControllerModes.COOL)"
                           :ir-controller="irController"
                           :controller-modes="ControllerModes"
                           :disabled="irController.mode !== ControllerModes.COOL"/>
      <v-divider></v-divider>
      <!-- fan mode -->
      <v-list-item>
        <v-list-item-icon>
            <span style="display: inline-block; vertical-align: middle" :class="$vuetify.theme.isDark ? 'theme--dark' : 'theme--light'" class="v-icon theme--light material-symbols-outlined">mode_fan</span>
        </v-list-item-icon>
        <v-list-item-title class="font-size-03">{{ $t('device-card-ir-controller.setpoint-fans') }}</v-list-item-title>
      </v-list-item>
      <mode-control-slider :has-step="true"
                           key="fan"
                           color="success"
                           :loading="loadingFanModes"
                           @change-mode-value="(...args) => setModeValue(args, ControllerModes.FAN_ONLY)"
                           :fan-modes="FanModes"
                           :ir-controller="irController"
                           :received-value="fanMode"
                           :controller-modes="ControllerModes"
                           :disabled="irController.mode === 0"/>
    </sub-devices>
  </device-card>
</template>

<script>
import DeviceCard from "@/templates/components/devices/DeviceCard.vue";
import SubDevices from "@/templates/components/devices/SubDevices.vue";
import ModeControlSlider from "@/templates/components/living/irController/modeControl/ModeControlSlider.vue";
import ModeSwitch from "@/templates/components/living/irController/ModeSwitch.vue";


export default {
  name: 'DeviceCardIRController',

  components: {ModeSwitch, ModeControlSlider, SubDevices, DeviceCard},

  props: {
    device: Object
  },

  data: function () {
    return {
      ControllerModes: this.$irControllerOperations.ControllerModes,
      FanModes: this.$irControllerOperations.FanModes,
      changedMode: null,
      loadingControllerMode: false,
      loadingHeatingSetpoint: false,
      loadingCoolingSetpoint: false,
      loadingFanModes: false
    }
  },

  methods: {
    getActuatorValue(propertyName) {
      let result = this.device.actuators.filter(item => {
        return item.name === propertyName
      })
      return result[0]?.value
    },

    setModeValue(args, controllerMode) {
      args[0].mode = controllerMode
      switch (controllerMode) {
        case this.ControllerModes.HEAT:
          this.loadingHeatingSetpoint = true
          break
        case this.ControllerModes.COOL:
          this.loadingCoolingSetpoint = true
          break
        case this.ControllerModes.FAN_ONLY:
          this.loadingFanModes = true
          break
        default:
          return
      }

      this.$irControllerOperations.setModeValue(args,
        () => {
          this.loadingCoolingSetpoint = false
          this.loadingHeatingSetpoint = false
          this.loadingFanModes = false
      },
      () => {
        this.loadingCoolingSetpoint = false
        this.loadingHeatingSetpoint = false
        this.loadingFanModes = false
        this.$root.bisatoast.error({
          message: this.$t("ir-controller.set-controller-mode.error")
        })
      })
    },

    setControllerMode (...args) {
      this.loadingControllerMode = true
      this.$irControllerOperations.setControllerMode(args,
        () => {
          if (args[1] === this.ControllerModes.RESUME) {
            setTimeout(() => {
              this.$irControllerOperations.getActualThermostatMode(args[0].id,
                (resp) => {
                  this.loadingControllerMode = false
                  this.irController.mode = resp.data.data
                },
                () => {
                  this.loadingControllerMode = false
                  this.$root.bisatoast.error({
                    message: this.$t("ir-controller.set-controller-mode.error")
                  })
                })
            }, 3000) // delay execution of the request getActualThermostatMode
          } else {
            this.loadingControllerMode = false
          }
        },
        () => {
          this.loadingControllerMode = false
          this.$root.bisatoast.error({
            message: this.$t("ir-controller.set-controller-mode.error")
          })
        }
      )
    }
  },

  computed: {
    irThermostatMode () {
      return this.getActuatorValue('ir-thermostat-mode')
    },
    fanMode () {
      return this.getActuatorValue('ir-thermostat-fan-mode')
    },
    setpointHeating () {
      return this.getActuatorValue('ir-temperature-setpoint-heating')
    },
    setpointCooling () {
      return this.getActuatorValue('ir-temperature-setpoint-cooling')
    },
    irController () {
      return {
        id: this.device.id,
        mode: this.getActuatorValue('ir-thermostat-mode')
      }
    }
  },

  watch: {
    irThermostatMode(newValue) {
      this.irController.mode = newValue
    }
  }
}


</script>
