<template>
  <div class="page-content">

    <v-speed-dial class="pulse"
                  :right="true"
                  :bottom="true">
      <template v-slot:activator>
        <v-btn class="speed-dial-button"
               fab fixed bottom right
               color="primary"
               @click="openAddDialog">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </v-speed-dial>

    <v-skeleton-loader v-if="loading"
                       type="article"
                       height="300"/>

    <v-row v-else-if="!loading && ((items && Object.values(items).length > 0) || hasOcppChargeStations())">
      <v-col cols="12" sm="6" lg="4" v-for="chargingStation in Object.values(items)"
             v-bind:key="chargingStation[0].attributes.chargingStation.id">
        <content-card

            :title="chargingStation[0].attributes.chargingStation.name"
            class="mb-6"
            icon="ev_station"
            color="primary">
          <template v-slot:content>
            <chargingstation-card :charging-points="chargingStation"
                                  :enabled-connection-ids="enabledConnectionIds"
                                  :chargingstation-id="chargingStation[0].attributes.chargingStation.id"
                                  :compleo-connection-id="compleoConnectionId"/>
          </template>
        </content-card>
      </v-col>

      <v-col cols="12" sm="6" lg="4" v-for="ocppChargePoint in ocppChargePoints"
             v-bind:key="ocppChargePoint.chargeBoxId">
        <content-card :title="ocppChargePoint.name"
                      class="mb-6"
                      icon="ev_station"
                      color="primary">
          <template v-slot:titleBar>
            <v-btn icon class="float-right"
                   @click="showDetails(ocppChargePoint)">
              <v-icon>open_in_full</v-icon>
            </v-btn>
          </template>
          <template v-slot:content>
            <ocpp-charge-point-card :connectors="ocppChargePoint.connectors"
                                    :charge-point="ocppChargePoint"/>
          </template>
        </content-card>
      </v-col>
    </v-row>

    <!-- Empty State -->
    <div v-else>
      <empty-state
          :empty-state-title="$t('emobility-page.emptyState.title').toString()"
          :empty-state-text="$t('emobility-page.emptyState.text').toString()">
        <template v-slot:illustration>
          <emobility-empty-state-illustration
              :fill-primary="'var(--v-primary-base)'"
              :fill-secondary="'var(--v-secondary-base)'"
          />
        </template>
      </empty-state>
    </div>

  </div>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import EmptyState from "@/templates/components/emptyStates/EmptyState";
import EmobilityEmptyStateIllustration from "@/templates/components/emptyStates/svg/EmobilityEmptyStateIllustration.vue";
import config from '@/config/config.app.json'
import requestHelper from "@/scripts/requestHelper";
import ChargingstationCard from "@/templates/components/emobility/ChargingstationCard.vue";
import OcppChargePointCard from "@/templates/components/emobility/OcppChargePointCard.vue";

export default {
  name: "EMobilityPage",

  components: {ChargingstationCard, ContentCard, EmptyState, EmobilityEmptyStateIllustration, OcppChargePointCard},

  data() {
    return {
      refreshRate: config.updateInterval,
      loading: false,
      ocppChargePoints: [],
      compleoConnectionId: null,
      items: [],
      timer: null,
      enabledConnectionIds: null
    }
  },

  methods: {
    /**
     * opens overlay to add new charging stations
     */
    openAddDialog() {
      this.$root.bisadialog.toggle('addChargingStation')
    },
    /**
     * get all compleoConnections
     */
    getCompleoConnections(showLoader) {
      if (showLoader) {
        this.loading = true
      }
      this.$rhRequest.sendGet({
        endpoint: 'chargingstations/compleoConnections',
        params: {
          include: "chargingPoints",
        }
      }, (resp) => {
        if (resp?.data?.data?.included) {
          this.compleoConnectionId = resp.data.data.data[0].id
          let included = resp.data.data.included

          this.enabledConnectionIds = resp.data.data?.currentlyEnabled

          // group (reduce) included chargingpoints to their respective chargingstation. reason for this: on emobility page, every charginstation
          // has its own card with its chargingpoints. included data is not grouped, therefore has to be done here
          this.items = included.reduce((acc, d) => {
            if (Object.keys(acc).includes(d.attributes.chargingStation.id)) return acc;
            acc[d.attributes.chargingStation.id] = included.filter(g => g.attributes.chargingStation.id === d.attributes.chargingStation.id);
            return acc;
          }, {})
        }
        this.loading = false
      }, (error) => {
        console.error(error)
        this.loading = false
      })
    },
    getOcppChargePoints() {
      this.$rhRequest.sendGet({
        endpoint: 'ocpp/get-charge-points'
      }, (resp) => {
        if (resp?.data?.code !== -1) {
          this.ocppChargePoints = resp?.data?.data
        }
        this.loading = false
      }, (error) => {
        console.error(error)
        this.loading = false
      })
    },

    hasOcppChargeStations() {
      return (/true/).test(config.hasOcppChargingStations) && this.ocppChargePoints && this.ocppChargePoints.length > 0
    },

    loadData(showLoader) {
      this.getCompleoConnections(showLoader)
      this.getOcppChargePoints(showLoader)
    },

    showDetails(ocppChargePoint) {
      this.$root.bisadialog.toggle('ocppChargePointDetails', true, ocppChargePoint)
    },

    /**
     * continuously refresh data
     */
    updateInterval() {
      this.timer = setInterval(() => {
        this.loadData(false)
      }, this.refreshRate)
    }
  },

  mounted() {
    this.loading = true
    requestHelper.startDelayedRequest(
        () => this.loadData(true),
        () => this.updateInterval()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/documents.scss';
</style>
