<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       width="100%" viewBox="0 0 1090 300" enable-background="new 0 0 1090 300" xml:space="preserve">
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M708.020081,245.834869
	C702.222839,245.834869 696.425659,245.834869 690.316467,245.834869
	C690.316467,211.252777 690.316467,176.883148 690.316467,142.007141
	C696.270386,142.007141 702.059998,141.883011 707.835449,142.113892
	C708.964050,142.159012 710.475281,143.337036 711.071045,144.404846
	C720.141968,160.662811 729.150269,176.956543 738.041565,193.313492
	C742.674377,201.836227 747.050293,210.498611 751.116821,218.283569
	C750.607483,193.574890 750.082825,168.127029 749.551025,142.331787
	C756.385925,142.331787 762.431213,142.331787 768.732178,142.331787
	C768.732178,176.745987 768.732178,210.999191 768.732178,245.959549
	C762.337891,245.959549 755.846802,246.484787 749.537842,245.643555
	C747.613098,245.386902 745.916748,241.518951 744.579041,239.053513
	C732.401367,216.609436 720.326721,194.109497 708.214233,171.629990
	C708.077637,171.376495 707.839783,171.177551 706.800659,169.954361
	C707.469299,180.779602 708.070435,190.512070 708.572083,201.023376
	C708.321777,216.479752 708.170898,231.157318 708.020081,245.834869
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M526.756165,223.250305
	C533.777161,231.540894 549.531067,232.896072 558.137634,229.201187
	C559.823669,228.477356 561.691101,226.011627 561.823608,224.226715
	C562.277893,218.106339 561.997986,211.931488 561.997986,205.200378
	C555.742188,205.200378 549.679749,205.200378 543.316162,205.200378
	C543.316162,199.701523 543.316162,194.772202 543.316162,189.418777
	C555.210571,189.418777 567.256958,189.418777 580.001404,189.418777
	C580.001404,193.560471 580.001404,197.822327 580.001404,202.084198
	C580.001404,210.581451 579.196472,219.177414 580.223083,227.548767
	C581.294434,236.285492 576.200195,240.038361 569.988037,242.833755
	C555.336243,249.426865 540.369080,250.319382 525.164612,244.128952
	C512.618225,239.020767 504.146759,229.689362 500.365631,217.307877
	C494.260956,197.317795 494.553711,177.322647 507.016296,159.288315
	C515.572021,146.907471 528.001099,140.925919 542.868530,140.029968
	C555.958618,139.241150 567.561462,142.826706 576.910217,152.436554
	C577.351868,152.890518 577.694458,153.440796 578.150574,154.033890
	C574.777649,158.051041 571.521545,161.929031 568.297974,165.768326
	C564.622375,163.521561 561.103760,160.785873 557.145569,159.056061
	C542.727661,152.755173 524.643738,160.245834 519.494995,177.087341
	C515.124207,191.384308 515.230103,205.504318 523.013855,218.845062
	C523.910706,220.382233 525.325195,221.617371 526.756165,223.250305
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M945.949585,222.077179
	C948.543152,230.194031 951.087708,237.908142 953.716675,245.878281
	C946.733398,245.878281 940.255981,245.878281 933.296326,245.878281
	C930.879700,237.706955 928.568970,229.444611 925.897339,221.300644
	C925.450256,219.938004 923.461060,218.180969 922.144531,218.146957
	C912.002258,217.884827 901.850098,218.002670 891.437378,218.002670
	C888.760803,227.332123 886.128296,236.508057 883.457275,245.818344
	C877.048279,245.818344 870.758423,245.818344 863.548645,245.818344
	C874.897522,210.967911 886.111877,176.530197 897.356445,141.999969
	C903.727783,141.999969 909.719238,142.143829 915.698975,141.940979
	C918.821960,141.835052 920.162476,142.782913 921.168701,145.968185
	C928.968811,170.660675 937.050354,195.264267 945.058411,219.890945
	C945.260071,220.511154 945.616455,221.081070 945.949585,222.077179
M914.899170,181.677856
	C912.758545,174.067566 910.617920,166.457291 908.383179,158.512375
	C904.260132,173.386841 900.211243,187.993668 896.093140,202.850174
	C904.478943,202.850174 912.427917,202.850174 920.746399,202.850174
	C918.794006,195.936035 916.896729,189.217041 914.899170,181.677856
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M668.000000,237.038849
	C668.000000,240.152573 668.000000,242.783417 668.000000,245.704376
	C646.322876,245.704376 625.060852,245.704376 603.398743,245.704376
	C603.398743,211.410797 603.398743,177.032257 603.398743,142.325958
	C624.403687,142.325958 645.450378,142.325958 666.747253,142.325958
	C666.747253,147.420181 666.747253,152.351059 666.747253,157.867447
	C652.326477,157.867447 637.938110,157.867447 623.279541,157.867447
	C623.279541,166.707809 623.279541,174.970627 623.279541,183.818024
	C635.282410,183.818024 647.340149,183.818024 659.692505,183.818024
	C659.692505,189.319839 659.692505,194.250381 659.692505,199.766937
	C647.770020,199.766937 635.711365,199.766937 623.331238,199.766937
	C623.331238,210.057236 623.331238,219.770493 623.331238,229.889709
	C638.087830,229.889709 652.687439,229.889709 668.000000,229.889709
	C668.000000,232.372986 668.000000,234.464493 668.000000,237.038849
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M970.448669,220.250122
	C973.343140,221.714828 975.915039,223.337326 978.476746,224.975830
	C987.168762,230.535278 996.479309,232.864471 1006.613586,229.943039
	C1011.466858,228.544022 1016.171021,223.200333 1015.799927,218.833176
	C1015.402649,214.158661 1013.198242,210.018311 1008.534119,207.914307
	C999.598450,203.883362 990.493835,200.198608 981.722900,195.839813
	C975.180969,192.588699 969.617371,187.963959 966.907532,180.738785
	C961.016724,165.032501 967.154053,151.924057 978.756042,145.108276
	C994.998108,135.566666 1020.931519,139.491638 1032.072021,153.939270
	C1029.474487,157.344757 1026.985962,160.965302 1024.052368,164.179550
	C1023.391357,164.903732 1020.772400,164.792542 1019.745605,164.075974
	C1013.254150,159.545807 1006.388367,156.274612 998.262939,157.038940
	C991.053833,157.717056 986.658325,159.497421 984.925903,165.560822
	C983.486450,170.598969 984.304138,174.508881 988.826965,176.803955
	C997.959106,181.438019 1007.250305,185.797028 1016.668701,189.817947
	C1026.237061,193.902908 1033.108154,200.371506 1034.847900,210.723633
	C1036.915405,223.026230 1033.493164,234.112564 1022.929443,241.294601
	C1017.862854,244.739273 1011.324280,247.088135 1005.220581,247.730499
	C989.158203,249.420898 974.164185,246.217041 961.868225,234.550125
	C960.185303,232.953262 959.396118,232.212418 961.158691,230.339325
	C964.212708,227.093796 967.140625,223.729630 970.448669,220.250122
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M317.058228,179.011292
	C326.213501,183.045746 335.098389,186.802628 343.768982,191.001999
	C356.608032,197.220276 361.909485,207.731247 359.976837,222.255081
	C358.496948,233.376221 347.098083,244.887161 335.408905,246.964905
	C320.162476,249.674942 305.657562,248.132568 292.513672,239.203659
	C290.372498,237.749084 288.136322,236.345795 286.325836,234.536728
	C285.604980,233.816391 285.838776,232.059753 285.818512,230.772049
	C285.812958,230.418854 286.468475,230.063187 286.806274,229.695419
	C289.703949,226.540436 292.168396,221.626984 295.629395,220.779327
	C298.411011,220.098083 302.363129,224.400864 305.857422,226.383636
	C313.900177,230.947403 322.430511,232.579819 331.437408,229.960617
	C336.649597,228.444901 339.818665,224.846466 340.242706,219.310135
	C340.609314,214.523544 338.755768,210.769379 334.259857,208.671600
	C325.089539,204.392761 315.827606,200.305649 306.719025,195.900482
	C295.684204,190.563690 287.734344,178.162308 289.049774,168.502090
	C290.879822,155.062454 297.983337,145.769714 311.215576,141.967239
	C327.942932,137.160370 342.971588,140.406372 355.863129,152.391891
	C356.326843,152.823029 356.665833,153.388321 357.098328,153.935928
	C353.818268,158.013153 350.648254,161.953583 347.957336,165.298492
	C342.280792,162.732666 337.048279,159.443756 331.354706,158.081314
	C326.654663,156.956619 321.232269,157.361160 316.460541,158.487930
	C312.507477,159.421341 309.475403,162.739792 309.000214,167.417847
	C308.394104,173.384735 312.196320,176.384628 317.058228,179.011292
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M786.516724,142.499008
	C812.991272,142.168533 839.124756,142.168533 865.628479,142.168533
	C865.628479,147.305466 865.628479,152.352646 865.628479,157.806335
	C855.996033,157.806335 846.398499,157.806335 836.237183,157.806335
	C836.237183,187.238800 836.237183,216.280334 836.237183,245.658844
	C829.297363,245.658844 822.918640,245.658844 816.146484,245.658844
	C816.146484,216.608444 816.146484,187.687241 816.146484,158.190643
	C806.059143,158.190643 796.336792,158.190643 786.175781,158.190643
	C786.175781,152.691650 786.175781,147.760574 786.516724,142.499008
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M426.000122,237.930481
	C426.000122,205.833679 426.000122,174.226257 426.000122,142.310059
	C432.309723,142.310059 438.236267,142.310059 444.724121,142.310059
	C444.724121,171.202728 444.724121,200.237366 444.724121,229.837952
	C459.310699,229.837952 473.358185,229.837952 487.698303,229.837952
	C487.698303,235.323700 487.698303,240.252869 487.698303,245.593475
	C467.440552,245.593475 447.070618,245.593475 426.000122,245.593475
	C426.000122,243.272491 426.000122,240.846161 426.000122,237.930481
z"/>
    <path :fill="fontColor"  opacity="1.000000" stroke="none"
          d="
M387.074158,246.000000
	C384.617432,246.000000 382.645355,246.000000 380.338104,246.000000
	C380.338104,211.317703 380.338104,176.970779 380.338104,142.311249
	C386.304871,142.311249 392.228394,142.311249 398.578644,142.311249
	C398.578644,176.516098 398.578644,210.862869 398.578644,246.000000
	C395.049500,246.000000 391.304138,246.000000 387.074158,246.000000
z"/>
    <path :fill="logoColor1" opacity="1.000000" stroke="none"
          d="
M131.562500,1.000000
	C134.892929,3.312836 137.578629,5.963585 140.711670,7.884893
	C165.128159,22.858103 189.612076,37.721790 214.114227,52.554779
	C227.203339,60.478600 240.400818,68.223938 253.450195,76.211906
	C254.666779,76.956612 255.891891,78.870728 255.894791,80.244446
	C256.016479,137.904556 255.999207,195.564957 255.999207,253.612488
	C248.124420,253.612488 240.398712,253.612488 232.000000,253.612488
	C232.000000,251.809174 232.000000,249.881180 232.000000,247.953171
	C232.000000,197.619888 231.957443,147.286499 232.085205,96.953537
	C232.094849,93.153297 230.900681,91.060036 227.634140,89.109756
	C206.404175,76.434380 185.308533,63.533890 164.181229,50.686852
	C152.917770,43.837803 141.721649,36.875626 130.360535,30.193369
	C129.268036,29.550797 126.940643,30.068855 125.656265,30.835310
	C111.261879,39.425133 96.968109,48.183350 82.630684,56.868893
	C63.549622,68.428108 44.453899,79.963127 25.138622,91.645134
	C25.138622,153.001801 25.138622,214.639969 25.138622,277.108948
	C380.167694,276.405853 735.115234,275.702942 1090.531372,275.000000
	C1091.000000,282.687592 1091.000000,290.375153 1090.531372,298.531372
	C1070.106323,299.000061 1050.149658,298.959900 1030.193970,299.086151
	C1028.791992,299.095032 1027.397827,300.333374 1026.000000,301.000000
	C684.453186,301.000000 342.906311,301.000000 1.000000,301.000000
	C1.000000,226.646164 1.000000,152.291733 1.363104,77.461945
	C22.134912,64.659821 42.540409,52.327747 62.953224,40.007805
	C83.518288,27.595980 104.094093,15.201921 124.630463,2.742837
	C124.978134,2.531916 124.886589,1.597049 125.000000,1.000000
	C127.041664,1.000000 129.083328,1.000000 131.562500,1.000000
z"/>
    <path :fill="logoColor2" opacity="1.000000" stroke="none"
          d="
M142.754791,160.255585
	C113.016411,131.391678 77.661514,117.114746 36.320976,116.949532
	C36.320976,114.305580 36.320976,111.919296 36.320976,109.104652
	C73.103882,109.152107 106.412788,119.453262 134.981232,142.651657
	C173.795349,174.169830 192.591461,215.507401 192.577408,265.707092
	C190.355209,265.707092 187.949890,265.707092 184.947296,265.707092
	C184.557800,225.235031 171.074921,189.919266 142.754791,160.255585
z"/>
    <path :fill="logoColor2" opacity="1.000000" stroke="none"
          d="
M126.735504,186.236221
	C139.662262,200.268173 147.785858,216.529175 151.826859,234.625427
	C154.060303,244.627197 154.675690,254.990311 156.085281,265.682068
	C152.293869,265.682068 149.908112,265.682068 147.117416,265.682068
	C148.038986,234.708191 137.317551,208.272507 115.739098,186.284439
	C94.036369,164.169785 67.073692,154.582016 36.292534,154.182159
	C36.292534,151.619034 36.292534,149.558319 36.292534,147.324585
	C47.517139,146.035019 58.478542,147.335052 69.113411,150.177353
	C89.232407,155.554443 106.791328,165.736908 121.725525,180.307236
	C123.483002,182.021896 124.902374,184.083084 126.735504,186.236221
z"/>
    <path :fill="logoColor2" opacity="1.000000" stroke="none"
          d="
M43.922112,192.000412
	C41.152260,192.000504 38.852924,192.000504 36.279716,192.000504
	C36.279716,189.225540 36.279716,186.841827 36.279716,184.475723
	C78.584442,180.388702 120.428543,217.578812 117.781967,265.608551
	C115.435081,265.608551 113.048782,265.608551 110.763733,265.608551
	C109.850586,258.766815 109.604965,251.648712 107.876686,244.910904
	C101.178024,218.795731 79.250046,197.504181 52.168182,193.007584
	C49.591618,192.579773 46.985195,192.331787 43.922112,192.000412
z"/>
    <path :fill="logoColor3" opacity="1.000000" stroke="none"
          d="
M37.619484,259.045410
	C35.244942,253.024994 35.163425,247.562759 39.071171,242.410614
	C42.139912,238.364670 49.705101,235.643524 55.526421,238.218933
	C60.749847,240.529846 65.077316,246.652176 64.957016,252.079132
	C64.821411,258.196198 59.956444,264.381042 54.117809,265.859039
	C48.058098,267.392975 41.448471,264.737793 37.619484,259.045410
z"/>
</svg>
</template>

<script>
export default {
  name: 'MainLogo',
  computed: {
    logoColor1() {
      return this.$vuetify.theme.dark ? '#FFFFFF' : '#FF6600'
    },
    logoColor2() {
      return this.$vuetify.theme.dark ? '#FFFFFF' : '#959595'
    },
    logoColor3() {
      return this.$vuetify.theme.dark ? '#FFFFFF' : '#C00000'
    },
    fontColor() {
      return this.$vuetify.theme.dark ? '#FFFFFF' : '#4472C4'
    },
  }
}
</script>
