<template>
  <dialog-frame :title="$t('registration-code-dialog.title').toString()"
                icon="mark_email_read"
                color="primary"
                closable
                @close="abort">
    <template v-slot:content>
      {{ $t('registration-code-dialog.text') }}
      <v-otp-input outlined
                   v-model="code"
                   class="mt-5 code-input mx-auto font-size-06"
                   :rules="reqRules"
                   length="5"/>
      <v-btn plain small
             @click="pasteCode">
        <v-icon small left>content_paste</v-icon>
        {{ $t('app.paste') }}
      </v-btn>
    </template>

    <template v-slot:actions>
      <v-btn plain block
             color="primary"
             class="font-weight-bold d-block"
             @click="checkCode"
             :loading="loading">
        {{ $t('app.continue') }}
      </v-btn>
    </template>
  </dialog-frame>
</template>

<script>

import DialogFrame from "@/templates/dialogs/DialogFrame";

export default {
  name: 'RegistrationCodeDialog',

  components: {
    DialogFrame
  },

  data: () => ({
    code: '',
    loading: false
  }),

  methods: {
    pasteCode() {
      navigator.clipboard.readText().then(text => {
        this.code = text
      })
    },

    checkCode() {
      this.loading = true

      this.$rhRequest.sendPost({
        endpoint: "registration/check-activation-code",
        data: {code: this.code}
      }, (response) => {
        this.$root.bisadialog.toggle('registrationCode')
        if (response?.data?.data?.valid) {
          this.$root.bisadialog.toggle('registrationCodeValid', true, {email: response.data.data.email})
        } else {
          this.$root.bisadialog.toggle('registrationCodeError')
        }
        this.loading = false
      }, () => {
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        this.loading = false
      })
    },

    abort() {
      this.$root.bisadialog.toggle('registrationCode')
    }
  },

  computed: {
    reqRules() {
      return [
        v => !!v || this.$t('app.rules.required'),
      ]
    }
  }
};
</script>

