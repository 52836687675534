<template>
  <dialog-frame :title="$t('registration-code-error-dialog.title').toString()"
                icon="error_outline"
                color="error">
    <template v-slot:content>
      {{ $t('registration-code-error-dialog.text') }}
    </template>

    <template v-slot:actions>
      <v-btn plain block
             color="error"
             class="font-weight-bold d-block"
             @click="back">
        {{ $t('app.go-back') }}
      </v-btn>
    </template>
  </dialog-frame>
</template>

<script>

import DialogFrame from "@/templates/dialogs/DialogFrame";
export default {
  name: 'RegistrationCodeErrorDialog',

  components: {
    DialogFrame
  },

  methods: {
    back() {
      this.$root.bisadialog.toggle('registrationCodeError', false)
      this.$root.bisadialog.toggle('registrationCode', true)
    }
  }

};
</script>

