<template>
  <fullscreen-overlay-frame :title="name?.toString()"
                            icon="ev_station"
                            color="primary"
                            centered
                            closable
                            maximizable
                            @maximize="maximize"
                            @minimize="minimize"
                            @close="close">
    <template v-slot:content>
      <v-row>
        <!-- general information -->
        <v-col cols="12" :md="fullscreen ? '4' : '12'">
          <content-card :title="$t('ocpp-charge-point-details-dialog.info.title').toString()"
                        icon="info">
            <template v-slot:content>

              <v-list class="pa-0">
                <!-- charge point name -->
                <v-list-item class="list-item straight">
                  <v-list-item-icon>
                    <v-icon class="material-icons-outlined">label</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content v-if="editName"
                                       :class="editName ? 'pb-0' : ''">
                    <v-form v-model="nameValid" lazy-validation>
                      <v-text-field outlined dense
                                    counter="25"
                                    ref="name-input"
                                    :rules="nameRules"
                                    v-model="name"/>
                    </v-form>
                  </v-list-item-content>
                  <v-list-item-content v-else>
                    <v-list-item-title class="font-size-03"
                                       v-text="name ?? '-'"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-text="$t('ocpp-charge-point-details-dialog.infos.name')"/>
                  </v-list-item-content>
                  <v-list-item-action v-if="editName">
                    <v-btn icon
                           :disabled="!nameValid"
                           :loading="changingName"
                           @click="updateName">
                      <v-icon>check</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action v-else-if="isSiteAdmin">
                    <v-btn icon
                           @click="changeName">
                      <v-icon class="non-flip">edit</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <!-- charge box id -->
                <v-list-item class="list-item straight">
                  <v-list-item-icon>
                    <v-icon class="material-icons-outlined">fingerprint</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-03" v-text="data?.chargeBoxId"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-text="$t('add-ocpp-charge-point-dialog.charge-box-id')"/>
                  </v-list-item-content>
                </v-list-item>

                <!-- charge point manufacturer -->
                <v-list-item class="list-item straight">
                  <v-list-item-icon>
                    <v-icon class="material-icons-outlined">store</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-03" v-text="data?.chargePointVendor"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-text="$t('ocpp-charge-point-details-dialog.infos.vendor')"/>
                  </v-list-item-content>
                </v-list-item>

                <!-- charge point model -->
                <v-list-item class="list-item straight">
                  <v-list-item-icon>
                    <v-icon class="material-icons-outlined">category</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-03" v-text="data?.chargePointModel"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-text="$t('ocpp-charge-point-details-dialog.infos.model')"/>
                  </v-list-item-content>
                </v-list-item>

                <!-- charge point serial -->
                <v-list-item class="list-item straight">
                  <v-list-item-icon>
                    <v-icon class="material-icons-outlined">tag</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-03" v-text="data?.chargePointSerialNumber"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-text="$t('ocpp-charge-point-details-dialog.infos.serial')"/>
                  </v-list-item-content>
                </v-list-item>

                <!-- charge point firmware -->
                <v-list-item class="list-item straight">
                  <v-list-item-icon>
                    <v-icon class="material-icons-outlined">local_offer</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-03" v-text="data?.fwVersion"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-text="$t('ocpp-charge-point-details-dialog.infos.fw-version')"/>
                  </v-list-item-content>
                </v-list-item>

                <!-- charge point heartbeat -->
                <v-list-item class="list-item straight">
                  <v-list-item-icon>
                    <v-icon class="material-icons-outlined">monitor_heart</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-03" v-text="formatTime(data?.lastHeartbeat)"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-text="$t('ocpp-charge-point-details-dialog.infos.last-heartbeat')"/>
                  </v-list-item-content>
                </v-list-item>

                <!-- amount connectors -->
                <v-list-item class="list-item straight">
                  <v-list-item-icon>
                    <v-icon class="material-icons-outlined">electrical_services</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-03" v-text="data?.connectors?.length"/>
                    <v-list-item-subtitle class="font-size-02"
                                          v-text="$t('ocpp-charge-point-details-dialog.infos.amount-connectors')"/>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
          </content-card>
        </v-col>

        <!-- transaction history -->
        <v-col cols="12" :md="fullscreen ? '8' : '12'">
          <content-card :title="$t('ocpp-charge-point-details-dialog.transactions.title').toString()"
                        icon="history">
            <template v-slot:titleBar>
              <v-btn icon text
                     class="float-right"
                     @click="getTransactions">
                <v-icon>refresh</v-icon>
              </v-btn>
            </template>

            <template v-slot:content>
              <v-data-table :loading="transactions?.loading"
                            :headers="transactions?.headers"
                            :footer-props="{'items-per-page-options': [10]}"
                            :items="transactions?.data">
                <template v-slot:[`footer.prepend`]>
                  <v-spacer/>
                </template>
                <template v-slot:[`header.connectorId`]>
                  <v-icon small>electrical_services</v-icon>
                </template>
                <template v-slot:[`item.startTimestamp`]="{ item }">
                  {{ formatTime(item.startTimestamp) }}
                </template>
                <template v-slot:[`item.stopTimestamp`]="{ item }">
                  {{ formatTime(item.stopTimestamp) }}
                </template>
                <template v-slot:[`item.totalImport`]="{ item }">
                  <span v-if="item.stopValue >= item.startValue">
                    {{ item.stopValue - item.startValue }} Wh <!-- TODO unit is not available in the response; might defer for other charging stations -->
                  </span>
                  <span v-else>-</span>
              </template>
              </v-data-table>
            </template>
          </content-card>
        </v-col>
      </v-row>
    </template>

    <template v-slot:actions>
      <v-btn outlined
             class="font-weight-bold action-button"
             :disabled="activeConnectors?.length > 0"
             color="primary"
             @click="removeChargePoint">
        <v-icon left
                class="material-icons-outlined">
          delete
        </v-icon>
        {{ $t('app.delete') }}
      </v-btn>

      <v-btn outlined
             class="font-weight-bold action-button mt-4"
             color="primary"
             @click="resetChargePoint">
        <v-icon left
                class="material-icons-outlined">
          restart_alt
        </v-icon>
        {{ $t('ocpp-charge-point-details.reset.button') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import ContentCard from "@/templates/components/ContentCard.vue";
import formats from "@/scripts/formats";
import ocpp from "@/scripts/ocpp";

export default {
  name: 'OcppChargePointDetailsDialog',
  components: {
    ContentCard,
    FullscreenOverlayFrame
  },

  props: ['data'],

  data: function () {
    return {
      fullscreen: false,
      name: this.data.name,
      editName: false,
      changingName: false,
      nameValid: true,
      nameRules: [
        v => !!v || this.$t('app.rules.required'),
        v => (v && v.length <= 25) || this.$t('app.rules.too-long', {maxLength: 25})
      ],
      transactions: {
        loading: false,
        headers: [
          {text: this.$t('occp-charge-point-card.connector.label'), value: 'connectorId', sortable: false},
          {text: this.$t('ocpp-charge-point-details-dialog.headers.start-timestamp'), value: 'startTimestamp'},
          {text: this.$t('ocpp-charge-point-details-dialog.headers.stop-timestamp'), value: 'stopTimestamp'},
          {text: this.$t('ocpp-charge-point-details-dialog.headers.total-import'), value: 'totalImport', sortable: false},
          {text: this.$t('ocpp-charge-point-details-dialog.headers.stop-reason'), value: 'stopReason', sortable: false},
          {
            text: this.$t('ocpp-charge-point-details-dialog.headers.stop-event-actor'),
            value: 'stopEventActor',
            sortable: false
          },
        ],
        data: []
      }
    }
  },

  computed: {
    ocpp() {
      return ocpp
    },

    isSiteAdmin() {
      return this.$rhAuth.isSiteAdmin()
    },

    activeConnectors() {
      return this.data?.connectors?.filter(con => con.status === ocpp.connectorStates.charging)
    }
  },

  methods: {
    /**
     * get all transactions for this charge point
     */
    getTransactions() {
      this.transactions.loading = true
      this.$rhRequest.sendGet({
        endpoint: "ocpp/get-transactions",
        params: {
          chargeBoxId: this.data.chargeBoxId,
        }
      }, (resp) => {
        this.transactions.data = resp.data.data
        this.transactions.loading = false
      }, (error) => {
        console.error(error)
        this.transactions.loading = false
      })
    },

    /**
     * sets the dialog in name edit mode
     */
    changeName() {
      this.editName = true
      this.$nextTick(() => { // wait until refs are defined
        this.$refs['name-input'].focus()
      })
    },

    /**
     * updates the charge point name
     */
    updateName() {
      this.changingName = true
      this.$rhRequest.sendPost({
        endpoint: "ocpp/update-charge-point",
        data: {
          chargePointPK: this.data.chargePointPK,
          name: this.name,
        }
      }, () => {
        this.editName = false
        this.changingName = false
      }, (error) => {
        console.error(error)
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        this.changingName = false
      })
    },

    removeChargePoint() {
      this.$root.bisadialog.toggle('confirmation', true, {
        maxWidth: 350,
        title: this.$t('confirmation-dialog.title'),
        text: this.$t('ocpp-charge-point-details.remove.confirm'),
        confirmLabel: this.$t('app.delete'),
        chargePointPK: this.data.chargePointPK
      })
      this.$root.$on('dialogConfirmed', () => {
        this.$rhRequest.sendGet({
          endpoint: 'ocpp/remove-charge-point',
          params: {
            chargePointPk: this.data.chargePointPK
          }
        }, () => {
          this.$root.bisatoast.success({message: this.$t('ocpp-charge-point-details.remove.success')})
          this.close()
        }, (error) => {
          console.error(error)
          this.$root.bisatoast.error({message: this.$t('app.generic-error')})
          this.loading = false
        })
        this.$root.$off('dialogConfirmed')
      })
    },

    resetChargePoint() {
      this.$root.bisadialog.toggle('confirmation', true, {
        maxWidth: 350,
        title: this.$t('confirmation-dialog.title'),
        text: this.$t('ocpp-charge-point-details.reset.confirm'),
        confirmLabel: this.$t('ocpp-charge-point-details.reset.button'),
        color: 'error',
        icon: 'warning',
        chargeBoxId: this.data.chargeBoxId
      })
      this.$root.$on('dialogConfirmed', () => {
        this.$rhRequest.sendPost({
          endpoint: 'ocpp/soft-reset',
          data: {
            chargeBoxId: this.data.chargeBoxId
          }
        }, () => {
          this.$root.bisatoast.success({message: this.$t('ocpp-charge-point-details.reset.success')})
          this.close()
        }, (error) => {
          console.error(error)
          this.$root.bisatoast.error({message: this.$t('app.generic-error')})
          this.loading = false
        })
        this.$root.$off('dialogConfirmed')
      })
    },

    formatTime(timestamp) {
      return formats.formatDateString(timestamp, this.$t('app.date-time-format.long-seconds'))
    },

    /**
     * maximize dialog
     */
    maximize() {
      this.fullscreen = true
      this.$emit('maximize')
    },

    /**
     * minimize dialog
     */
    minimize() {
      this.fullscreen = false
      this.$emit('minimize')
    },

    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('ocppChargePointDetails')
    }
  },

  mounted() {
    this.getTransactions()
  }
};
</script>

