<template>
  <div>
    <v-skeleton-loader v-if="loading" type="card"/>
    <content-card :title="$t('meter-overview-card.title')"
                  class="mb-2"
                  icon="electric_meter">

      <template v-slot:content>
        <!-- Iterate over the meters -->
        <div v-for="(meter, index) in meters" :key="index" class="meter-row">

          <div class="d-flex justify-space-between">

            <div class="flex-fill px-5 py-4">
              <!-- Meter name -->
              <div class="font-size-03 mb-3" :class="isOnline(meter) ? 'primary--text font-weight-bold' :''">
                {{ meter.name }}
              </div>

              <!-- Feed properties -->
              <div v-if="meter.energyDataType === 'meter'" class="property-wrapper" :class="{offline: !isOnline(meter)}">
                <v-icon class="mr-3" :color="getIconColor(isOnline(meter))">electrical_services</v-icon>
                <span class="flex-fill">
                  <div>
                    {{ getSensorNameTranslation(meter.energyDataType, 'feed-current') }}
                  </div>
                  <div :class="{feedCurrentColor: isOnline(meter)}">
                    {{ getSensorValueText(meter, 'feed-current') }}
                  </div>
                </span>
                <v-icon class="mr-3" :color="getIconColor(isOnline(meter))">electrical_services</v-icon>
                <span class="flex-fill">
                  <div>
                    {{ getSensorNameTranslation(meter.energyDataType, 'feed-total') }}
                  </div>
                  <div>
                    {{ getSensorValueText(meter, 'feed-total') }}
                  </div>
                </span>
              </div>

              <!-- Consumption properties -->
              <div class="property-wrapper">
                <v-icon v-if="meter.energyDataType === 'meter'" class="mr-3" :color="getIconColor(isOnline(meter))">electric_meter</v-icon>
                <v-icon v-else class="mr-3" :color="getIconColor(isOnline(meter))">solar_power</v-icon>
                <span class="flex-fill">
                  <div>
                    {{ getSensorNameTranslation(meter.energyDataType, 'consumption-current') }}
                  </div>
                  <div :class="{consumptionCurrentColor: meter.energyDataType === 'meter' && isOnline(meter)}">
                    {{ getSensorValueText(meter, 'consumption-current') }}
                  </div>
                </span>
                <v-icon v-if="meter.energyDataType === 'meter'" class="mr-3" :color="getIconColor(isOnline(meter))">electric_meter</v-icon>
                <v-icon v-else class="mr-3" :color="getIconColor(isOnline(meter))">solar_power</v-icon>
                <span class="flex-fill">
                  <div>
                    {{ getSensorNameTranslation(meter.energyDataType, 'consumption-total') }}
                  </div>
                  <div>
                    {{ getSensorValueText(meter, 'consumption-total') }}
                  </div>
                </span>
              </div>
            </div>

            <div style="align-content: center">
              <!-- Button for opening the detail dialog -->
              <v-btn icon small class="px-0" @click="openDetailDialog(meter)">
                <v-icon class="material-icons-outlined" color="primary">chevron_right</v-icon>
              </v-btn>
            </div>

          </div>

          <!-- Divider between meters -->
          <v-divider v-if="index < meters.length - 1"></v-divider>
        </div>

      </template>
    </content-card>
  </div>
</template>


<script>
import ContentCard from "@/templates/components/ContentCard";
import requestHelper from "@/scripts/requestHelper";
import config from "@/config/config.app.json";
import formats from "@/scripts/formats";

export default {
  name: "MeterOverviewCard",
  components: {ContentCard},

  data: function () {
    return {
      loading: true,
      timer: null,
      meters: []
    }
  },

  methods: {
    updateData() {
      this.timer = setInterval(() => {
        this.getData()
      }, config.updateInterval)
    },

    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }

      this.$rhRequest.sendGet({
        endpoint: 'devices/get-meters-for-home-energy'
      }, (response) => {
        this.meters = response?.data?.data
        this.loading = false
        this.$emit('finishedMetersLoading', this.meters?.length > 0)
      }, (error) => {
        console.error(error)
        this.loading = false
        this.$emit('finishedMetersLoading', this.meters?.length > 0)
      })
    },

    /**
     * returns true if the device is online
     *
     * @param device
     * @returns {boolean}
     */
    isOnline(device) {
      return device?.online && device?.gatewayOnline
    },

    /**
     * Converts a sensor value to a localized string representation.
     * @param sensor
     * @returns {string|number}
     */
    formatValue(sensor) {
      return sensor?.value ? formats.localize(sensor) : 0
    },

    /**
     * Searches for the sensor with the given name within the given sensors.
     * @param sensors
     * @param name
     * @returns {*}
     */
    getSensor(sensors, name) {
      return sensors.find(sensor => sensor.name === name);
    },

    /**
     * This function returns the translation text for the given meter type (meter, photovoltaic_inverter) and the sensor name.
     * @param meterType
     * @param sensorName
     * @returns {*|string}
     */
    getSensorNameTranslation(meterType, sensorName) {
      const translations = {
        meter: {
          'feed-current': this.$t('meter-overview-card.feed-current'),
          'feed-total': this.$t('meter-overview-card.feed-total'),
          'consumption-current': this.$t('meter-overview-card.consumption-current'),
          'consumption-total': this.$t('meter-overview-card.consumption-total')
        },
        photovoltaic_inverter: {
          'consumption-current': this.$t('meter-overview-card.pvi-consumption-current'),
          'consumption-total': this.$t('meter-overview-card.pvi-consumption-total')
        }
      };

      // Check if the meterType exists in translations
      if (translations[meterType] && translations[meterType][sensorName]) {
        return translations[meterType][sensorName];
      }

      return '';
    },

    /**
     * This function returns a concatenated string with the sensor value and unit for the given meter and sensor name.
     * @param meter
     * @param sensorName
     * @returns {string}
     */
    getSensorValueText(meter, sensorName) {
      let sensor = this.getSensor(meter.sensors, sensorName)
      let sensorValueText = this.formatValue(sensor)
      let sensorUnitText = sensor.unit
      return sensorValueText + ' ' + sensorUnitText
    },

    /**
     * This function opens the detail dialog for the given meter.
     * @param meter
     */
    openDetailDialog(meter) {
      this.$root.bisadialog.toggle('device', true, {device: meter})
      this.$root.bisadialog.callDialogInit('device')
    },

    getIconColor(isColorized) {
      let color = this.$vuetify.theme.isDark ? 'theme--dark' : 'theme--light'
      if (isColorized) {
        color = 'primary'
      }
      return color
    }
  },

  mounted() {
    requestHelper.startDelayedRequest(
      () => this.getData(true),
      () => this.updateData()
    )
  },

  computed: {
    formats() {
      return formats
    }
  },

  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss">
@import '~@/styles/homeEnergy/meter-overview-card';
</style>
