<template>
  <fullscreen-overlay-frame :title="$t('add-charging-station-dialog.ocpp.add').toString()"
                            icon="control_point_duplicate"
                            color="primary"
                            centered
                            closable
                            @close="close">
    <template v-slot:content>
      <div v-html="$t('add-ocpp-charge-point-dialog.info')"
            class="mb-8"/>

      <v-form v-model="valid" ref="form">
        <v-text-field outlined class="my-2"
                      v-model="chargeBoxId"
                      :rules="rules.chargeBoxId"
                      :label="$t('add-ocpp-charge-point-dialog.charge-box-id')"/>

        <v-text-field outlined
                      v-model="name"
                      :rules="rules.name"
                      counter="30"
                      :label="$t('add-ocpp-charge-point-dialog.charge-point-name')"/>
      </v-form>
    </template>

    <template v-slot:actions>
      <v-btn large depressed
             color="primary"
             class="font-weight-bold action-button"
             @click="addChargePoint"
             :disabled="!valid">
        {{ $t('app.save') }}
      </v-btn>
    </template>

  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";

export default {
  name: 'AddOcppChargePointDialog',
  components: {
    FullscreenOverlayFrame
  },

  props: ['data'],

  data: function () {
    return {
      valid: true,
      chargeBoxId: null,
      name: null,
      rules: {
        chargeBoxId: [
          v => !!v || this.$t('app.rules.required')
        ],
        name: [
          v => !!v || this.$t('app.rules.required'),
          v => (v && v.length <= 30) || this.$t('app.rules.too-long', {maxLength: 30})
        ]
      }
    }
  },

  methods: {
    addChargePoint() {
      this.$refs.form.validate()
      this.$rhRequest.sendPost({
        endpoint: "ocpp/add-charge-point",
        data: {
          chargeBoxId: this.chargeBoxId,
          name: this.name
        }
      }, () => {
        this.$root.bisatoast.success({
          message: this.$t('add-ocpp-charge-point-dialog.add.success'),
          showCloseBtn: true
        })
        this.close()
      }, (error) => {
        console.error(error)
        if (error.response?.status === 409) {
          this.$root.bisatoast.error({
            message: this.$t('add-ocpp-charge-point-dialog.add.error'),
            showCloseBtn: true
          })
        }
      })
    },

    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('addOcppChargePoint')
    }
  }
};
</script>

