<template>
  <div v-if="connectors?.length > 0">
    <ocpp-connector v-for="(connector, idx) in connectors" v-bind:key="idx"
                    class="pa-5"
                    :connector="connector"
                    :charge-point="chargePoint"/>
  </div>
  <div v-else-if="chargePoint.lastHeartbeat">
    <v-alert type="info"
             class="mb-0" text>
      {{ $t('ocpp-charge-point-card.no-connectors') }}
    </v-alert>
  </div>
  <div v-else>
    <v-alert type="info" icon="build"
             class="mb-0" text>
      {{ $t('ocpp-charge-point-card.no-heartbeat') }}
    </v-alert>
  </div>
</template>

<script>
import OcppConnector from "@/templates/components/emobility/OcppConnector.vue";

export default {
  name: "OcppChargePointCard",
  components: {OcppConnector},

  props: [
    'connectors',
    'chargePoint'
  ],
}
</script>
