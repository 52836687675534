<template>
  <div>
    <v-skeleton-loader v-if="loading"
                       type="card"/>
    <content-card :title="irController.name"
                  class="mb-2"
                  :is-material-symbol="true"
                  v-for="(irController, i) in irControllers" v-bind:key="irController.id"
                  icon="mode_heat_cool">
      <!-- button for opening ir controller detail page from card -->
      <template v-slot:titleBar>
        <v-btn icon small class="float-end px-0 mt-1" @click="openDeviceDetailPage(irController)">
          <v-icon class="material-icons-outlined" color="primary">chevron_right</v-icon>
        </v-btn>
      </template>
      <template v-slot:content>
        <!-- mode switch area, contains on/off switch and mode buttons -->
        <span style="position: relative">
          <v-overlay v-if="loadingController[i]" color="navBackground" absolute :value="loadingController[i]" z-index="4" opacity="0.7">
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-overlay>
          <mode-switch :controller-modes="ControllerModes" :loading="loadingController[i]" :irController="irControllerForModeSwitch(irController)" @set-controller-mode="setControllerMode"/>
          <v-divider/>
          <mode-control-slider v-if="[ControllerModes.HEAT, ControllerModes.COOL, ControllerModes.FAN_ONLY].includes(irControllerForModeSwitch(irController).mode)"
                               :loading="loadingSlider[i]"
                               :disabled="irControllerForModeSwitch(irController).mode === ControllerModes.OFF"
                               :controller-modes="ControllerModes"
                               :fan-modes="FanModes"
                               :irController="irController"
                               @change-mode-value="setModeValue"
                               :received-value="getSliderValue(irController)"
                               :has-step="irControllerForModeSwitch(irController).mode === ControllerModes.FAN_ONLY"/>
        </span>
        <v-divider v-if="[ControllerModes.HEAT, ControllerModes.COOL, ControllerModes.FAN_ONLY].includes(irControllerForModeSwitch(irController).mode)"/>
        <!-- temperature sensor area -->
        <div v-if="getSensorValue(irController, 'temperature-sensor')" class="sensor-area pa-5 d-flex justify-space-between">
          <div class="my-auto">
            <v-icon class="mr-2" color="primary">thermostat</v-icon>
            <span>{{ $t('ir-controller-card.room-temperature.label') + ':' }}</span>
          </div>
          <v-chip color="primary">{{ getSensorValue(irController, 'temperature-sensor') + $t('app.unit.celsius') }}</v-chip>
        </div>
        <v-divider v-if="getSensorValue(irController, 'temperature-sensor') && irController.humidity" />
        <div v-if="irController.humidity" class="sensor-area pa-5 d-flex justify-space-between">
          <div class="my-auto">
            <v-icon class="mr-2" color="primary">thermostat</v-icon>
            <span>{{ $t('device.sensor.humidity') + ':' }}</span>
          </div>
          <v-chip color="primary">{{ irController.humidity + ' ' + $t('app.unit.percent') }}</v-chip>
        </div>
      </template>
    </content-card>
  </div>
</template>


<script>

import ModeSwitch from "@/templates/components/living/irController/ModeSwitch.vue";
import ContentCard from "@/templates/components/ContentCard";
import ModeControlSlider from "@/templates/components/living/irController/modeControl/ModeControlSlider.vue";
import requestHelper from "@/scripts/requestHelper";
import config from "@/config/config.app.json";

export default {
  name: "IRControllerCard",
  components: {ModeControlSlider, ContentCard, ModeSwitch},

  data: function () {
    return {
      ControllerModes: this.$irControllerOperations.ControllerModes,
      FanModes: this.$irControllerOperations.FanModes,
      loading: true,
      loadingController: [],
      loadingSlider: [],
      irControllers: [],
      timer: null
    }
  },

  methods: {
    updateData() {
      this.timer = setInterval(() => {
        this.getData()
      }, config.updateInterval)
    },

    getData(showLoader, stopControllerLoader) {
      if (showLoader) {
        this.loading = true
      }

      this.$rhRequest.sendGet({
        endpoint: 'devices/get',
        params: {
          deviceType: 'infrared-controller'
        }
      }, (response) => {

        if(stopControllerLoader) {
          this.loadingSlider = []
          this.loadingController = []
        }

        this.irControllers = Object.values(response?.data?.data)
        if (!this.irControllers || this.irControllers.length < 1) {
          this.$emit('hide-ircontroller', true)
        }

        this.loading = false
      }, (error) => {
        console.error(error)
        this.$emit('hide-ircontroller', true)
        this.loading = false
      })
    },

    getSliderValue(controller) {
      let value = null
      switch (controller.mode) {
        case this.ControllerModes.HEAT:
          value = this.getActuatorValue(controller, 'ir-temperature-setpoint-heating')
          break
        case this.ControllerModes.COOL:
          value = this.getActuatorValue(controller, 'ir-temperature-setpoint-cooling')
          break
        case this.ControllerModes.FAN_ONLY:
          value = this.getActuatorValue(controller, 'ir-thermostat-fan-mode')
          break
        default:
          break
      }
      return value
    },

    setModeValue(...args) {
      if (!args[0]) return
      let irController = args[0]
      this.$set(this.loadingSlider, this.getLoadingIndex(irController.id), true)
      this.$irControllerOperations.setModeValue(args,
        () => {
          setTimeout(() => {
            this.getData(false, true)
          }, 3000)
        },
        () => {
          this.$root.bisatoast.error({
            message: this.$t("ir-controller.set-controller-mode.error")
          })
        })
    },

    getActualThermostatMode(deviceId) {
      this.$rhRequest.sendGet({
        endpoint: 'devices/get-active-ir-controller-mode',
        params: {
          deviceId: deviceId.toString()
        }
      }, () => {
        this.getData(false, true)
      }, (error) => {
        this.$set(this.loadingController, this.getLoadingIndex(deviceId), false)
        this.$root.bisatoast.error({
          message: this.$t("ir-controller.set-controller-mode.error")
        })
        console.error(error)
      })
    },

    getLoadingIndex(id) {
      return this.irControllers.findIndex(x => x.id === id)
    },

    setControllerMode(...args) {
      if (!args[0]) return
      let irController = args[0]
      this.$set(this.loadingController, this.getLoadingIndex(irController.id), true)
      this.$irControllerOperations.setControllerMode(args,
        () => {
          setTimeout(() => {
            this.getActualThermostatMode(args[0].id)
          }, 3000)
        },
        () => {
          this.$set(this.loadingController, this.getLoadingIndex(irController.id), false)
          this.$root.bisatoast.error({
            message: this.$t("ir-controller.set-controller-mode.error")
          })
        }
      )
    },
    // open ir controller device detail page after clicking chevron
    openDeviceDetailPage(irController) {
      this.$root.bisadialog.toggle('device', true, {device: irController})
      this.$root.bisadialog.callDialogInit('device')
    },
    irControllerForModeSwitch (irController) {
      return {
        id: irController.id,
        mode: this.getActuatorValue(irController,'ir-thermostat-mode')
      }
    },
    getActuatorValue(irController, propertyName) {
      let result = irController.actuators.filter(item => {
        return item.name === propertyName
      })
      return result[0]?.value
    },
    getSensorValue(irController, propertyName) {
      let result = irController.sensors.filter(item => {
        return item.name === propertyName
      })
      return result[0]?.value
    }
  },

  mounted() {
    requestHelper.startDelayedRequest(
      () => this.getData(true),
      () => this.updateData()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/ir-controller-card';
</style>
