<!--
  Template for generic content cards (like on the pages inbox, documents, support, ...).
  Provides a slot "content" for any content. See 'props' for title bar props.
-->
<template>
  <v-card class="content-card">
    <v-card-title class="pa-0 content-card-header text-no-wrap"
                  :class="!!headerImgSrc ? 'background-image' : ''"
                  :style="backgroundImage">
      <div class="pa-5 pb-6"
           :class="!!headerImgSrc ? 'background-gradient': ''">
        <v-icon left
                v-if="icon && !isMaterialSymbol"
                :class="isNonFlipIcon(icon) ? 'material-icons-outlined non-flip' : 'material-icons-outlined' "
                :color="color">
          {{ icon }}
        </v-icon>
        <span style="display: inline-block; vertical-align: middle; font-size: 24px" v-else-if="icon && isMaterialSymbol" :class="'material-symbols-outlined ' + color + '--text mr-2'">{{ icon }}</span>
        <span class="font-weight-bold font-size-04 mr-4"
              :class="color + '--text'"
              v-html="title"/>

        <!-- TODO This should not be part of a generic card component. A universal solution should be provided for this, such as an actions slot for the title bar (see: slot titleBar)-->
        <span v-if="isFavourable"
              @click="toggleFavorites"
              class="float-right">
          <v-icon v-if="isFavorite"
                  :color="color">
            favorite
          </v-icon>
          <span v-else
                style="font-size: 24px"
                class="mt-0 mr-0 material-symbols-outlined">
            favorite
          </span>
        </span>

        <slot name="titleBar"/>
      </div>
    </v-card-title>
    <v-card-text class="pa-0">
      <!-- TODO This should not be in a generic component. Please use the content slot and move it to the calling component(s) which needs to show the v-alert. -->
      <v-alert
          v-if="infoText"
          type="info"
          text
          class="font-size-03 ma-4"
          border="left">
        {{infoText}}
      </v-alert>
      <slot name="content"/>
    </v-card-text>
  </v-card>
</template>

<script>

import {isNonFlipIcon} from "@/i18n";

export default {
  name: "ContentCard",
  methods: {
    toggleFavorites () {
      this.$emit('toggleFavorites')
    },
    isNonFlipIcon
  },

  props: {
    icon: String,
    title: String,
    /**
     * Only pass the file name; files therefore has to be put into the images/headers folder.
     * This is needed for Webpack which tries to load the resource during compile time (see backgroundImage() for more).
     */
    headerImgSrc: String,
    color: {
      default: "primary",
      type: String
    },
    isMaterialSymbol: {
      default: false,
      type: Boolean
    },
    isFavourable: {
      default: false,
      type: Boolean
    },
    isFavorite: {
      default: false,
      type: Boolean
    },
    infoText: String,
  },

  computed: {
    /**
     * 'require' needs the complete path of the resource so that Webpack can load this folder during compile time.
     * @see https://stackoverflow.com/questions/42797313/webpack-dynamic-module-loader-by-require
     *
     * @returns {{}|{backgroundImage: string}}
     */
    backgroundImage() {
      if (this.headerImgSrc) {
        return {backgroundImage: 'url(' + require('@/assets/custom/images/headers/' + this.headerImgSrc) + ')'}
      }
      return {}
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/content-card.scss';
</style>
