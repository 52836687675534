<template>
  <fullscreen-overlay-frame :title="title.toString()"
                            icon="settings_suggest"
                            color="primary"
                            centered
                            closable
                            @close="close">
    <template v-slot:content>

      <v-card>
        <device-selection
            :items="data?.mecMeters"
            v-model="selectedMeter"
            :info-text="$t('sg-ready-dialog.select-device.info-text.' + getProperty(data?.action?.state)).toString()"/>
      </v-card>

      <s-g-ready-condition-selection
          v-model="condition"
          :device="data?.mecMeters[selectedMeter]"
          :property="getProperty(data?.action?.state)"/>
    </template>
    <template v-slot:actions>
      <v-btn large depressed
             color="primary"
             :disabled="loading"
             :loading="loading"
             class="font-weight-bold action-button"
             @click="save">
        {{ $t('app.save') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame.vue";
import DeviceSelection from "@/templates/dialogs/automations/DeviceSelection.vue";
import SGReadyConditionSelection from "@/templates/dialogs/sgReady/SGReadyConditionSelection.vue";

export default {
  name: 'SGReadyDialog',
  components: {
    SGReadyConditionSelection,
    DeviceSelection,
    FullscreenOverlayFrame
  },
  props: ['data'],
  data() {
    return {
      title: this.$t('sg-ready-dialog.title'),
      radioGroup: null,
      value: this.data?.value,
      selectedMeter: 0,
      condition: null,
      loading: false,
    }
  },
  methods: {
    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('sGReady')
    },
    getProperty(state) {
      if (state === 1) {
        return 'consumption-current'
      } else {
        return 'feed-current'
      }
    },
    checkToContinue() {
      if (this.data?.mecMeters.length === 1) {
        this.itemSelection = 0
        this.nextButtonClick()
      }
    },
    save() {
      this.loading = true
      if (!this.condition) {
        console.error('No condition')
        this.loading = false
        return
      }
      // clientId and siteId will be added by the proxy (PHP)
      let rule = {
        name: '',
        iconKey: '',
        deactivated: false,
        condition: this.condition,
        action: this.data?.action,
        synced: 'PENDING',
        hidden: true
      }

      if (this.data?.rule?.id) {
        // update existing rule
        this.$rhRequest.sendPost({
          endpoint: "rule/update",
          data: rule,
          params: {id: this.data?.rule.id}
        }, (resp) => {
          if (resp.data.code === 0) {
            this.$root.bisatoast.success({
              message: this.$t('sg-advanced-settings.save.rule-updated'),
              showCloseBtn: true
            })
            // notifies RulesCard to reload the rule items
            this.$root.$emit('reloadHomeEnergySGReady')
            this.close()
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          }
          this.loading = false
        }, (err) => {
          console.error(err)
          this.loading = false
          if (err.response.data.code === 580) {
            this.$root.bisatoast.warning({message: this.$t('add-rule-dialog.save.gateway-offline'), showCloseBtn: true})
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          }
          // notifies RulesCard to reload the rule items
          this.$root.$emit('reloadHomeEnergySGReady')
        })
      } else {
        // create new rule
        this.$rhRequest.sendPost({
          endpoint: "rule/add",
          data: rule
        }, (resp) => {
          if (resp.data.code === 0) {
            this.$root.bisatoast.success({
              message: this.$t('sg-advanced-settings.save.rule-created'),
              showCloseBtn: true
            })
            // notifies RulesCard to reload the rule items
            this.$root.$emit('reloadHomeEnergySGReady')
            this.close()
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          }
          this.loading = false
        }, (err) => {
          console.error(err)
          this.loading = false
          if (err.response.data.code === 580) {
            this.$root.bisatoast.warning({message: this.$t('add-rule-dialog.save.gateway-offline'), showCloseBtn: true})
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          }
          // notifies RulesCard to reload the rule items
          this.$root.$emit('reloadHomeEnergySGReady')
        })
      }
    },
  },
  mounted() {
    if (this.data?.rule !== null && this.data?.rule !== undefined) {
      this.condition = this.data?.rule.attributes?.condition
      for (let i = 0; i < this.data?.mecMeters.length; i++) {
        if (this.data?.mecMeters[i].id === this.data?.rule.attributes?.condition?.deviceId) {
          this.selectedMeter = i
          break
        }
      }
    } else {
      this.condition = {
        value: this.data?.value,
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
