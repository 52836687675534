<template>
  <entry-frame :title="$t('registration-selection-page.title').toString()">
    <template v-slot:subheader>
      <div v-html="$t('registration-selection-page.subtitle')"/>
      <v-btn text small
             color="primary"
             class="font-weight-bold"
             to="login">
        {{ $t('registration-selection-page.login') }}
      </v-btn>
    </template>

    <template v-slot:form>
      <!-- create new account card -->
      <v-card class="mb-6 registration-switch-card"
              to="registrationEmail">
        <div class="d-flex flex-no-wrap justify-space-between">
          <v-card-subtitle class="mt-1 d-flex">
            <v-icon class="align-self-center pr-3 mx-auto material-icons-outlined"
                    color="primary">
              account_circle
            </v-icon>
            <div class="align-self-center font-weight-bold mx-auto font-size-04"
                 v-html="$t('registration-switch.email-label')"></div>
          </v-card-subtitle>
          <v-card-actions>
            <v-icon color="primary" class="mt-3 material-icons-outlined">
              chevron_right
            </v-icon>
          </v-card-actions>
        </div>
      </v-card>

      <div class="text-center">
        <div class="font-size-02"
             v-if="canInviteAdditionalUser"
             v-html="$t('registration-switch.code-label')"/>
        <v-btn text small
               v-if="canInviteAdditionalUser"
               color="primary"
               class="font-weight-bold mt-2 mb-6 wrap-text"
               @click="$root.bisadialog.toggle('invitationCode')">
          {{ $t('registration-selection-page.redeem-invitation-code-button') }}
        </v-btn>

        <div class="font-size-02"
             v-html="$t('registration-switch.activation-code-label')"/>
        <v-btn text small
               color="primary"
               class="font-weight-bold mt-2 wrap-text"
               @click="$root.bisadialog.toggle('registrationCode')">
          {{ $t('registration-selection-page.redeem-activation-code-button') }}
        </v-btn>
      </div>
    </template>
  </entry-frame>
</template>

<script>
import EntryFrame from "@/templates/components/EntryFrame";
import config from "@/config/config.app.json";

export default {
  name: 'RegistrationSelection',

  components: {
    EntryFrame
  },

  computed: {
    canInviteAdditionalUser() {
      return !(config.canInviteAdditionalUser === 'false');
    }
  }
};
</script>

<style lang="scss">
@import '~@/styles/entry/registration.scss';
</style>
