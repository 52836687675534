<template>
  <div class="page-content">

    <v-row v-if="!showEmptyState">
      <v-col cols="12" sm="6" lg="4">
        <v-skeleton-loader v-if="loadingContacts"
                           type="article"
                           height="300"/>
        <!-- contacts card -->
        <content-card v-else-if="contacts?.length > 0"
                      :title="$t('support-page.contacts.title').toString()"
                      icon="perm_contact_calendar"
                      color="primary">
          <template v-slot:content>
            <v-list subheader class="py-0 tileBackground">
              <v-list-item v-for="contact in contacts"
                           :key="contact.id"
                           class="list-item straight px-5"
                           link
                           @click="openContact(contact)">
                <v-list-item-title class="font-size-02 primary--text font-weight-bold"
                                   v-text="contact.title"/>
                <v-list-item-action>
                  <v-icon color="primary">chevron_right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </template>
        </content-card>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <v-skeleton-loader v-if="loadingForms"
                           type="article"
                           height="300"/>
        <!-- forms card -->
        <content-card v-else-if="forms?.length > 0 || hubspotElements?.length > 0"
                      :title="$t('support-page.forms.title').toString()"
                      icon="feed"
                      color="primary">
          <template v-slot:content>
            <v-list subheader class="py-0 tileBackground">
              <v-list-item v-for="form in forms"
                           :key="form.id"
                           class="list-item straight px-5"
                           link
                           @click="openForm(form)">
                <v-list-item-title class="font-size-02 primary--text font-weight-bold"
                                   v-text="form.subject"/>
                <v-list-item-action>
                  <v-icon color="primary">chevron_right</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-for="hubspotElement in hubspotElements"
                           :key="hubspotElement.id"
                           class="list-item straight px-5"
                           link
                           @click="openHubspotElement(hubspotElement)">
                <v-list-item-title class="font-size-02 primary--text font-weight-bold"
                                   v-text="hubspotElement.name"/>
                <v-list-item-action>
                  <v-icon color="primary">chevron_right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </template>
        </content-card>
      </v-col>

      <v-col v-if="isSiteAdmin" cols="12" sm="6" lg="4">
        <v-skeleton-loader v-if="loadingSupportAccess"
                           type="article"
                           height="300"/>
        <!-- request support button -->
        <content-card v-else
                      :title="$t('support-page.support-access.title').toString()"
                      icon="support"
                      color="primary">
          <template v-slot:content>
            <v-list-item class="list-item straight px-5"
                         :class="{'disabled' : supportAccessActive}"
                         :disabled="supportAccessActive" @click="openRequestSupportDialog">
              <v-list-item-content>
                <v-list-item-title class="font-size-02 font-weight-bold" :class="{'primary--text' : !supportAccessActive}">
                  {{ $t('support-page.support-access.grant') }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon :disabled="supportAccessActive" color="primary">chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="supportAccessActive" class="list-item straight">
              <v-list-item-content class="font-size-03">{{ getSupportAccessActiveInfoText() }}</v-list-item-content>
            </v-list-item>
          </template>
        </content-card>
      </v-col>
    </v-row>

    <!-- Emptystate -->
    <div v-else>
      <empty-state
          :empty-state-title="$t('support-page.emptyState.title').toString()"
          :empty-state-text="$t('support-page.emptyState.text').toString()">
        <template v-slot:illustration>
          <SupportEmptyStateIllustration
              :fill-primary="'var(--v-primary-base)'"
              :fill-secondary="'var(--v-secondary-base)'"
          />
        </template>
      </empty-state>
    </div>

  </div>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard"
import EmptyState from "@/templates/components/emptyStates/EmptyState"
import SupportEmptyStateIllustration from "@/templates/components/emptyStates/svg/SupportEmptyStateIllustration"
import config from "@/config/config.app.json"
import requestHelper from "@/scripts/requestHelper"
import moment from 'moment-timezone'

export default {
  name: "SupportPage",

  components: {ContentCard, EmptyState, SupportEmptyStateIllustration},

  data() {
    return {
      refreshRate: config.dataRefreshRate,
      loadingContacts: false,
      loadingForms: false,
      loadingSupportAccess: false,
      contacts: [],
      forms: [],
      hubspotElements: [],
      supportAccessActive: false,
      supportAccessExpire: null
    }
  },

  methods: {
    /**
     * gets matching contacts from the API
     */
    getContactData(showLoader) {
      if (showLoader) {
        this.loadingContacts = true
      }

      let self = this
      this.$rhRequest.sendGet({
        endpoint: 'building-contact/get-contacts'
      }, function (response) {
        self.contacts = response?.data?.data
        self.loadingContacts = false
      }, function (error) {
        console.error(error)
        self.loadingContacts = false
      })
    },

    /**
     * gets matching forms from the API
     */
    getFormData(showLoader) {
      if (showLoader) {
        this.loadingForms = true
      }
      let self = this

      this.$rhRequest.sendGet({
        endpoint: 'content-service/get-forms'
      }, function (response) {
        self.forms = response?.data?.data
        self.getHubspotElements()
        self.loadingForms = false
      }, function (error) {
        console.error(error)
        // empty list if not found is returned
        if (error.response && error.response.status === 404) {
          self.formList = []
        }
        self.getHubspotElements()
        self.loadingForms = false
      })
    },

    /**
     * This method requests the hubspot forms and calendars from the backend
     */
    getHubspotElements() {
      this.$rhRequest.sendGet({
        endpoint: 'content-service/get-hubspot-elements'
      },  (response)  => {
        this.hubspotElements = response?.data?.data
      }, function (error) {
        console.error(error)
        // empty list if not found is returned
        if (error.response && error.response.status === 404) {
          this.hubspotElements = []
        }
      })
    },

    getSupportAccess(showLoader) {
      if (showLoader) {
        this.loadingSupportAccess = true
      }

      this.$rhRequest.sendGet({
        endpoint: 'user/support-access-active'
      }, (response) => {
        this.supportAccessActive = response?.data?.data?.supportAccessActive
        this.supportAccessExpire = response?.data?.data?.supportAccessExpire
        this.loadingSupportAccess = false
      }, (error) => {
        console.error(error)
        this.loadingSupportAccess = false
      })
    },

    /**
     * opens a fullscreen overlay with contact details
     *
     * @param contact
     */
    openContact(contact) {
      this.$root.bisadialog.toggle('contact', true, contact)
    },

    /**
     * opens a fullscreen overlay with a request form
     *
     * @param form
     */
    openForm(form) {
      // show capture image button only on android devices
      // reason is, if file selection gets opened on ios device, the option "take picture" can't be disabled,
      // therefore the button "take picture" is redundant for ios devices
      let showCaptureButton = false
      if (window?.cordova?.platformId.toLowerCase() === 'android') {
        showCaptureButton = true
      }

      this.$root.bisadialog.toggle('form', true, {form: form, showCaptureButton: showCaptureButton})
    },

    /**
     * opens a fullscreen overlay for a hubspot form or calendar
     *
     * @param data
     */
    openHubspotElement(data) {
      let key = null
      if (data.formId) {
        key = 'hubspotForm'
      } else if (data.calendarUrl) {
        key = 'hubspotCalendar'
      }
      this.$root.bisadialog.toggle(key, true, data)
    },

    openRequestSupportDialog() {
      this.$root.bisadialog.toggle('confirmation', true, {
        maxWidth: 400,
        title: this.$t('confirmation-dialog.title'),
        text: this.$t('support-access-dialog.text'),
        confirmLabel: this.$t('support-access-dialog.confirm-label')
      })

      this.$root.$on('dialogConfirmed', () => {
        this.$rhRequest.sendPost(
            {
              endpoint: "user/activate-support-access"
            }, (response) => {
              this.supportAccessActive = response?.data?.data?.supportAccessActive
              this.supportAccessExpire = response?.data?.data?.supportAccessExpire
            }, (e) => {
              console.error(e)
              this.$root.bisatoast.error({
                message: this.$t("app.generic-error")
              })
            }
        )
        this.$root.$off('dialogConfirmed')
      })
    },

    getSupportAccessActiveInfoText() {
      let date = moment(this.supportAccessExpire.date).format(this.$t('app.date-time-format.date-only'))
      let time = moment(this.supportAccessExpire.date).format(this.$t('app.date-time-format.time-only'))

      return this.$t("support-page.support-access.active-info-text", {date: date, time: time})
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getContactData(false)
        this.getFormData(false)
        this.getSupportAccess(false)
      }, this.refreshRate)
    },
  },

  mounted() {
    this.loadingContacts = true
    requestHelper.startDelayedRequest(
        () => this.getContactData(true),
        () => this.updateData()
    )
    this.loadingForms = true
    requestHelper.startDelayedRequest(
        () => this.getFormData(true)
    )
    this.loadingSupportAccess = true
    requestHelper.startDelayedRequest(
        () => this.getSupportAccess(true)
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },

  computed: {
    showEmptyState() {
      return (!this.loadingForms || !this.loadingContacts || !this.loadingSupportAccess) && this.hasNoContent
    },
    /**
     * This function determines if the current user has no relevant content to display.
     *
     * This function checks the following conditions:
     * - There are no forms.
     * - There are no contacts.
     * - There are no HubSpot elements.
     * - The user is not a site admin (for support requests).
     *
     * @returns {boolean} `true` if no content exists and the user is not a site admin, otherwise `false`.
     */
    hasNoContent() {
      return !this.forms?.length > 0
        && !this.contacts?.length > 0
        && !this.hubspotElements?.length > 0
        && !this.isSiteAdmin
    },
    isSiteAdmin() {
      return this.$rhAuth.isSiteAdmin()
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/support-page.scss';
</style>
