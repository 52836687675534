<template>
  <div class="page-content">
    <v-speed-dial v-if="hasEnode" class="pulse" :right="true" :bottom="true">
      <template v-slot:activator>
        <v-btn class="speed-dial-button" fab fixed bottom right color="primary" @click="openAddDialog">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </v-speed-dial>

    <v-skeleton-loader v-if="isLoading" class="mt-3" type="article" height="300"/>

    <v-row v-show="!isLoading && hasContent()">

      <v-col cols="12" sm="6" lg="4" v-show="hasMeters">
        <meter-overview-card @finishedMetersLoading="finishedMetersLoading"></meter-overview-card>
      </v-col>

      <v-col cols="12" sm="6" lg="4" v-show="hasEnergyPrices">
        <real-time-energy-prices
          @finishedEnergyPricesLoading="finishedEnergyPricesLoading"></real-time-energy-prices>
      </v-col>

      <v-col cols="12" sm="6" lg="4" v-for="(enodeConnection, index) in enodeConnections" v-bind:key="index"
             v-show="!loadingEnode && (enodeConnections.length > 0)">
        <enode-connection-card @reloadConnectionDetails="getEnodeConnections(false)"
                               :connectionDetails="enodeConnection"/>
      </v-col>

      <v-col cols="12" sm="6" lg="4" v-for="(sgReadyModule, index) in sgReadyModules" v-bind:key="index"
             v-show="hasSGReadyModule && !loadingMecMeters && !loadingSGReadyModules && !loadingSGReadyRules">
        <s-g-ready-home-energy-card :sg-ready-module="sgReadyModule"
                                    :sg-ready-rules="sortedSGReadyRules[sgReadyModule.id]"
                                    :smart-meters="filteredMecMeters"
                                    @updateDevice="getSGReadyRelatedEntities(false)"/>
      </v-col>

    </v-row>

    <!-- Empty State -->
    <div v-if="!isLoading && !hasContent()">
      <empty-state
        :empty-state-title="$t('home-energy-page.emptyState.title').toString()"
        :empty-state-text="$t('home-energy-page.emptyState.text').toString()">
        <template v-slot:illustration>
          <emobility-empty-state-illustration
            :fill-primary="'var(--v-primary-base)'"
            :fill-secondary="'var(--v-secondary-base)'"
          />
        </template>
      </empty-state>
    </div>

  </div>
</template>

<script>
import EmptyState from "@/templates/components/emptyStates/EmptyState";
import EmobilityEmptyStateIllustration
  from "@/templates/components/emptyStates/svg/EmobilityEmptyStateIllustration.vue";
import config from '@/config/config.app.json'
import requestHelper from "@/scripts/requestHelper";
import EnodeConnectionCard from "@/templates/components/emobility/EnodeConnectionCard.vue";
import RealTimeEnergyPrices from "@/templates/components/RealTimeEnergyPrices.vue";
import MeterOverviewCard from "@/templates/components/homeEnergy/MeterOverviewCard.vue";
import SGReadyHomeEnergyCard from "@/templates/components/devices/SGReadyHomeEnergyCard.vue";

export default {
  name: "HomeEnergyPage",

  components: {
    SGReadyHomeEnergyCard,
    EnodeConnectionCard,
    EmptyState,
    EmobilityEmptyStateIllustration,
    RealTimeEnergyPrices,
    MeterOverviewCard
  },

  data() {
    return {
      refreshRate: config.updateInterval,
      loadingEnode: false,
      loadingSGReadyModules: false,
      loadingSGReadyRules: false,
      loadingMecMeters: false,
      hasSGReadyModule: false,
      loadingEnergyPrices: false,
      hasEnergyPrices: false,
      loadingMeters: false,
      hasMeters: false,
      timer: null,
      enodeConnections: [],
      sgReadyModules: [],
      sgReadyRules: [],
      mecMeters: [],
    }
  },

  methods: {
    openAddDialog() {
      this.$root.bisadialog.toggle('addEmobilityConnection', true)
    },

    /**
     * calls all get connection requests
     * @param showLoader
     */
    getVehicles(showLoader) {
      this.getEnodeConnections(showLoader)
    },

    /**
     * get all enodeConnections
     */
    getEnodeConnections(showLoader) {
      if (showLoader) {
        this.loadingEnode = true
      }
      this.$rhRequest.sendGet({
        endpoint: 'enode/get-vehicles',
      }, (resp) => {
        if (resp?.data?.data) {
          this.enodeConnections = resp.data.data
        }
        this.loadingEnode = false
      }, (error) => {
        console.error(error)
        this.loadingEnode = false
      })
    },

    getSGReadyRelatedEntities(showLoader) {
      if (showLoader) {
        this.loadingSGReadyModules = true
        this.loadingMecMeters = true
        this.loadingSGReadyRules = true
      }
      this.$rhRequest.sendGet({
        endpoint: 'devices/get?deviceType=double-smart-module',
      }, (resp) => {
        if (resp?.data?.data) {
          this.sgReadyModules = resp.data.data
          this.hasSGReadyModule = resp.data.code !== -1 && Object.keys(this.sgReadyModules).length > 0;
        }
        this.loadingSGReadyModules = false
      }, (error) => {
        console.error(error)
        this.hasSGReadyModule = false
        this.loadingSGReadyModules = false
      })

      // get curated SGReady rules
      this.$rhRequest.sendGet({
        endpoint: "rule/get-s-g-ready-rules",
      }, (response) => {
        if (response?.data?.data) {
          this.sgReadyRules = response.data.data
        }
        this.loadingSGReadyRules = false
      }, (error) => {
        console.error(error)
        this.loadingSGReadyRules = false
      })

      // get smart meter
      this.$rhRequest.sendGet({
        endpoint: 'devices/get?deviceType=meter-mec',
      }, (resp) => {
        if (resp?.data?.data) {
          this.mecMeters = resp.data.data
        }
        this.loadingMecMeters = false
      }, (error) => {
        console.error(error)
        this.loadingMecMeters = false
      })
    },

    finishedEnergyPricesLoading(hasData) {
      this.loadingEnergyPrices = false
      this.hasEnergyPrices = hasData
    },

    finishedMetersLoading(hasData) {
      this.loadingMeters = false
      this.hasMeters = hasData
    },

    /**
     * Checks if there is content to show for this page
     * @returns {boolean}
     */
    hasContent() {
      return this.enodeConnections.length > 0 || this.hasEnergyPrices || this.hasMeters || this.hasSGReadyModule
    },

    /**
     * continuously refresh data
     */
    updateInterval() {
      this.timer = setInterval(() => {
        this.getVehicles(false)
        this.getSGReadyRelatedEntities(false)
      }, this.refreshRate)
    },

    checkForRedirect() {
      if (this.$route.path === '/enodeRedirect') {
        setTimeout(() => {
          if (Object.keys(this.$route.query).length === 0) {
            this.$root.bisatoast.success({
              message: this.$t("enode.toast.success"),
              showCloseBtn: true
            })
          } else if (Object.keys(this.$route.query).length > 0) {
            this.$root.bisatoast.error({
              message: this.$t('app.generic-error'),
              showCloseBtn: true
            })
          }
          this.$router.push('homeEnergy')
        }, 500);
      }
    },
  },
  computed: {
    hasEnode() {
      return Object.hasOwn(config, 'enodeRedirect')
    },
    isLoading() {
      let loading = true
      if (!this.loadingEnode || !this.loadingEnergyPrices || !this.loadingMeters) loading = false
      return loading
    },
    // Sort According to attributes.action.deviceId
    // Return array, of rules sorted by deviceId. deviceId as index
    sortedSGReadyRules() {
      if (!this.sgReadyRules) return []
      let sortedRules = {}
      for (let i = 0; i < this.sgReadyRules.length; i++) {
        let rule = this.sgReadyRules[i]
        if (sortedRules[rule.attributes.action.deviceId]) {
          sortedRules[rule.attributes.action.deviceId].push(rule)
        } else {
          sortedRules[rule.attributes.action.deviceId] = [rule]
        }
      }
      return sortedRules
    },
    filteredMecMeters() {
      if (!this.mecMeters) return []
      let filteredMecMeters = []
      for (const entry of Object.entries(this.mecMeters)) {
        let mecMeter = entry[1]
        if (mecMeter.energyDataType === 'meter') {
          filteredMecMeters.push(mecMeter)
        }
      }
      return filteredMecMeters
    }
  },
  mounted() {
    this.loadingEnode = true
    this.loadingEnergyPrices = true
    this.checkForRedirect()
    requestHelper.startDelayedRequest(
      () => this.getVehicles(true),
      () => this.getSGReadyRelatedEntities(true),
      () => this.updateInterval()
    )
    this.$root.$on('reloadHomeEnergySGReady', () => {
      this.getSGReadyRelatedEntities(false)
    })
  },

  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
