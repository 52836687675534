<template>
  <div>
    <div class="pb-5 d-flex justify-space-between">
      <div class="d-inline-block mr-1">
        <v-icon class="material-icons-outlined" color="primary">electrical_services</v-icon>
        <span class="pl-1 fill-height d-inline-block" style="line-height: 25px">
          {{ $t('occp-charge-point-card.connector.label') }} {{ connector?.connectorId }}
        </span>
      </div>

      <div v-if="isCharging"
           class="d-inline-block grow">
        <v-progress-linear reverse stream
                           buffer-value="0"
                           color="primary"
                           height="25">
          <template v-slot:default>
            <v-chip v-if="activeMeterValue"
                    color="tileBackground" text-color="primary"
                    class="px-1 font-weight-bold"
                    @click="changeMeasurand">
              {{ localize(activeMeterValue?.value) }} {{ activeMeterValue?.unit }}
            </v-chip>
          </template>
        </v-progress-linear>
      </div>

      <div class="d-inline-block">
        <v-icon class="material-icons-outlined" :color="getStateColor">
          {{ getStateIcon }}
        </v-icon>
        <span class="ml-1 d-inline-block" v-html="$t(getStateLabels)"/>
      </div>
    </div>

    <div class="d-block justify-space-between d-flex">
      <v-btn :disabled="buttonIsDisabled || loading"
             depressed large block
             class="font-weight-bold"
             color="primary"
             :loading="loading"
             @click="isCharging ? stopCharging() : startCharging()">
        {{
          isCharging ? $t('chargingstation-home-card.charging.stop') : $t('chargingstation-home-card.charging.start')
        }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import ocpp from "@/scripts/ocpp";
import config from "@/config/config.app.json";
import formats from "@/scripts/formats";

export default {
  name: "OcppConnector",

  props: [
    'connector',
    'chargePoint'
  ],

  data() {
    return {
      overviewChargingpoints: [],
      loading: false,
      meterPollInterval: null,
      activeMeterValue: null,
      measurand: ocpp.measurands.powerActiveImport
    }
  },

  computed: {
    buttonIsDisabled() {
      return !(this.connector.status === ocpp.connectorStates.available || this.connector.status === ocpp.connectorStates.charging);
    },

    isCharging() {
      return this.connector?.status === ocpp.connectorStates.charging
    },

    getStateColor() {
      switch (this.connector.status) {
        case ocpp.connectorStates.available:
          return 'success'
        case ocpp.connectorStates.charging:
          return 'primary'
        case ocpp.connectorStates.finishing:
        case ocpp.connectorStates.preparing:
          return 'info'
        case ocpp.connectorStates.suspendedEV:
        case ocpp.connectorStates.suspendedEVSE:
        case ocpp.connectorStates.reserved:
          return 'warning'
        case ocpp.connectorStates.unavailable:
        case ocpp.connectorStates.faulted:
          return 'error'
        default:
          return ''
      }
    },

    getStateLabels() {
      switch (this.connector.status) {
        case ocpp.connectorStates.available:
          return 'chargingstations.status.available'
        case ocpp.connectorStates.charging:
          return 'chargingstations.status.charging'
        case ocpp.connectorStates.finishing:
          return 'chargingstations.status.stopping' // TODO replace translation?
        case ocpp.connectorStates.preparing:
          return 'chargingstations.status.initializing'
        case ocpp.connectorStates.suspendedEV:
        case ocpp.connectorStates.suspendedEVSE:
          return 'chargingstations.status.suspended'
        case ocpp.connectorStates.unavailable: // TODO own translation?
        case ocpp.connectorStates.reserved:
          return 'chargingstations.status.occupied'
        case ocpp.connectorStates.faulted:
          return 'chargingstations.status.error'
        default:
          return ''
      }
    },

    getStateIcon() {
      switch (this.connector.status) {
        case ocpp.connectorStates.available:
          return 'contactless'
        case ocpp.connectorStates.charging:
          return 'electric_car'
        case ocpp.connectorStates.finishing:
          return 'stop_circle'
        case ocpp.connectorStates.preparing:
          return 'pending'
        case ocpp.connectorStates.suspendedEV:
        case ocpp.connectorStates.suspendedEVSE:
          return 'pause_circle'
        case ocpp.connectorStates.unavailable:
          return 'cancel'
        case ocpp.connectorStates.reserved:
          return 'remove_circle_outline'
        case ocpp.connectorStates.faulted:
          return 'error_outline'
        default:
          return 'help_outline'
      }
    },
  },

  methods: {
    startCharging() {
      this.loading = true
      this.$rhRequest.sendPost({
        endpoint: "ocpp/start-charging",
        data: {
          chargeBoxId: this.chargePoint.chargeBoxId,
          connectorId: this.connector.connectorId
        }
      }, () => {
        this.meterPollInterval = setInterval(() => {
          this.getMeterValues()
        }, config.updateInterval)
        // when SteVe is not reacting: reset loading status to allow user to retry starting
        setTimeout(() => {
          if (this.connector.status === ocpp.connectorStates.available) {
            this.loading = false
          }
        }, 10000)
      }, (error) => {
        console.error(error)
        this.loading = false
      })
    },

    stopCharging() {
      this.loading = true
      this.getActiveTransactionId((transactionId) => {
        if (!transactionId) {
          this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          return
        }
        this.$rhRequest.sendPost({
          endpoint: "ocpp/stop-charging",
          data: {
            chargeBoxId: this.chargePoint.chargeBoxId,
            transactionId: transactionId
          }
        }, () => {
          clearInterval(this.meterPollInterval)
          // when SteVe is not reacting: reset loading status to allow user to retry stopping
          setTimeout(() => {
            if (this.connector.status === ocpp.connectorStates.charging) {
              this.loading = false
            }
          }, 10000)
        }, (error) => {
          console.error(error)
          this.loading = false
        })
      }, (error) => {
        console.error(error)
        this.loading = false
      })
    },

    getActiveTransactionId(successCallback, errorCallback) {
      this.$rhRequest.sendGet({
        endpoint: "ocpp/get-active-transaction-id",
        params: {
          chargeBoxId: this.chargePoint.chargeBoxId,
          connectorId: this.connector.connectorId
        }
      }, (resp) => {
        successCallback(resp.data.data)
      }, (error) => {
        errorCallback(error)
      })
    },

    getMeterValues() {
      this.getActiveTransactionId((transactionId) => {
        if (transactionId) {
          this.$rhRequest.sendGet({
            endpoint: "ocpp/get-meter-values",
            params: {
              transactionId: transactionId,
              measurand: this.measurand
            }
          }, (resp) => {
            this.activeMeterValue = resp.data.data?.[0]
          }, (error) => {
            console.error(error)
          })
        }
      }, (error) => {
        console.error(error)
      })
    },

    changeMeasurand() {
      if (this.measurand === ocpp.measurands.currentImport) {
        this.measurand = ocpp.measurands.energyActiveImportRegister
      } else if (this.measurand === ocpp.measurands.energyActiveImportRegister) {
        this.measurand = ocpp.measurands.powerActiveImport
      } else {
        this.measurand = ocpp.measurands.currentImport
      }
      this.getMeterValues()
    },

    localize(value) {
      return formats.localize(value)
    }
  },

  watch: {
    connector(newVal, oldVal) {
      if (newVal.status !== oldVal.status) {
        this.loading = false // reset action button disabling when the connector gets a new status
      }
    }
  },

  mounted() {
    if (this.isCharging) {
      this.getMeterValues()
      this.meterPollInterval = setInterval(() => {
        this.getMeterValues()
      }, config.updateInterval)
    }
  },

  destroyed() {
    clearInterval(this.meterPollInterval)
  }
}
</script>
