<template>
  <div class="page-content">

    <v-skeleton-loader v-if="loading"
                       type="article"
                       height="300"/>

    <!-- documents card -->
    <content-card v-else-if="!loading && (items && items.length > 0)"
                  :title="$t('documents-page.title').toString()"
                  icon="folder_open"
                  color="primary">
      <template v-slot:content>
        <v-list subheader class="py-0 tileBackground">
          <v-list-item-group>
            <v-list-item v-for="item in items"
                         v-bind:key="item.id"
                         class="px-5 list-item"
                         @click="download(item.id, item.originalFileName, item.contentType)">
              <v-list-item-icon>
                <v-icon color="primary"
                        :class="isNonFlipIcon(getIcon(item.contentType)) ? 'non-flip material-icons-outlined' : 'material-icons-outlined'">
                  {{ getIcon(item.contentType) }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-size-02 primary--text font-weight-bold"
                                 v-html="item.name"/>
              <v-list-item-action class="font-size-02 primary--text text-no-wrap">
                {{ getFileSizeString(item.fileSize) }}
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </content-card>

    <content-card v-else-if="hasFileStorage && !loading && (files && files.length > 0)"
                  :title="$t('documents-page.title').toString()"
                  icon="folder_open"
                  color="primary">
      <template v-slot:content>
        <v-list subheader class="py-0 tileBackground">
          <v-list-item-group>
            <v-list-item v-for="item in files"
                         v-bind:key="item.id"
                         class="px-5 list-item">
              <v-list-item-icon>
                <v-icon :color="alreadyDownloaded(item.id) ? '' : 'primary'"
                        :class="isNonFlipIcon(getIcon(item.documentType)) ? 'non-flip material-icons-outlined' : 'material-icons-outlined'">
                  {{ getIcon(item.documentType) }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-size-02"
                                   :class="alreadyDownloaded(item.id) ? '' : 'primary--text font-weight-bold'"
                                   v-html="item.documentName"/>
                <v-list-item-subtitle class="font-size-01" v-text="item.contract.displayName"/>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn depressed small
                       v-if="item.fileStatus !== 'AVAILABLE'"
                       color="primary"
                       :disabled="item.fileStatus === 'DOWNLOADING'"
                       :loading="buttonLoadingAnimation[item.id]"
                       @click="prepareDocument(item)">
                  <v-icon left>cloud_download</v-icon>
                  {{ $t('documents-page.request-document') }}
                </v-btn>
                <v-btn depressed small
                       v-else-if="item.fileStatus === 'AVAILABLE'"
                       color="primary"
                       :loading="buttonLoadingAnimation[item.id]"
                       @click="downloadFromStorage(item)">
                  <v-icon left>download</v-icon>
                  {{ $t('documents-page.download-document') }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </content-card>

    <!-- Empty State -->
    <div v-else>
      <empty-state
          :empty-state-title="$t('documents-page.emptyState.title').toString()"
          :empty-state-text="$t('documents-page.emptyState.text').toString()">
        <template v-slot:illustration>
          <documents-empty-state-illustration
              :fill-primary="'var(--v-primary-base)'"
              :fill-secondary="'var(--v-secondary-base)'"
          />
        </template>
      </empty-state>
    </div>
  </div>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import EmptyState from "@/templates/components/emptyStates/EmptyState";
import DocumentsEmptyStateIllustration from "@/templates/components/emptyStates/svg/DocumentsEmptyStateIllustration";
import config from '@/config/config.app.json'
import requestHelper from "@/scripts/requestHelper";
import {isNonFlipIcon} from "@/i18n";

export default {
  name: "DocumentsPage",

  components: {ContentCard, EmptyState, DocumentsEmptyStateIllustration},

  data() {
    return {
      refreshRate: config.dataRefreshRate,
      loading: false,
      items: [],
      files: [],
      buttonLoadingAnimation: {}
    }
  },

  computed: {
    hasFileStorage() {
      return config.hasFileStorage === 'true'
    }
  },

  methods: {
    isNonFlipIcon,
    /**
     * get all documents for this site from the Content Service
     */
    getData(showLoader) {
      if (showLoader) {
        this.loading = true
      }
      this.$rhRequest.sendGet({
        endpoint: 'content-service/get-documents'
      }, (resp) => {
        this.items = resp?.data?.data?.data?.data
        this.loading = false
      }, (error) => {
        console.error(error)
        this.loading = false
      })

      if (this.hasFileStorage) {
        this.$rhRequest.sendGet({
          endpoint: 'consumption-data-service/documents'
        }, (resp) => {
          this.files = resp.data?.data
          this.loading = false
        }, (error) => {
          console.error(error)
          this.loading = false
        })
      }
    },

    /**
     * continuously refresh data
     */
    updateInterval() {
      this.timer = setInterval(() => {
        this.getData(false)
      }, this.refreshRate)
    },

    /**
     * call backend to prepare a file
     */
    prepareDocument(file) {
      file.fileStatus = 'DOWNLOADING'
      this.$root.bisatoast.info({message: this.$t('documents-page.prepare-document.info')})
      this.buttonLoadingAnimation[file.id] = true
      this.$rhRequest.sendGet({
        endpoint: 'consumption-data-service/prepare-document',
        params: {fileId: file.id}
      }, () => {
        this.buttonLoadingAnimation[file.id] = false
      }, (error) => {
        console.error(error)
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        this.buttonLoadingAnimation[file.id] = false
      })
    },

    /**
     * call backend to download a file from the RH storage
     * @param file
     */
    downloadFromStorage(file) {
      this.buttonLoadingAnimation[file.id] = true
      this.$rhRequest.sendGet({
        endpoint: 'consumption-data-service/download-document',
        responseType: 'arraybuffer',
        params: {fileName: file.storageFilename}
      }, (resp) => {
        this.downloadFile(resp.data, file.documentName, resp.headers['content-type'])
        this.addDownloadedMarker(file.id)
        this.buttonLoadingAnimation[file.id] = false
      }, (error) => {
        console.error(error)
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        this.buttonLoadingAnimation[file.id] = false
      })
    },

    /**
     * This function returns a matching icon for the passed content type
     * @param contentType Content type
     * @returns {string} Icon string
     */
    getIcon(contentType) {
      let icon = null

      // remove the charset string to determine the content type
      let delimiterIndex = contentType.indexOf(';')
      let type = delimiterIndex !== -1 ? contentType.substr(0, delimiterIndex).trim() : contentType.trim()

      switch (type) {
        case 'image/jpeg':
        case 'image/pjpeg':
        case 'image/png':
        case '.png':
        case '.jpg':
          icon = 'photo_library'
          break
        case 'application/pdf':
        case '.pdf':
          icon = 'picture_as_pdf'
          break
        default:
          icon = 'filter_none'
      }
      return icon
    },

    /**
     * returns a file size string including the file size and a unit
     *
     * @param size
     * @returns {string}
     */
    getFileSizeString: function (size) {
      if (size > 1000000) {
        return Math.round(size / 1000000) + " MB"
      } else {
        return Math.round(size / 1000) + " KB"
      }
    },

    /**
     * This function downloads a file using the Content Service
     *
     * @param documentId
     * @param originalFileName
     * @param contentType
     */
    download(documentId, originalFileName, contentType) {
      this.$rhRequest.sendGet({
        endpoint: 'content-service/download-document?documentId=' + documentId,
        responseType: 'arraybuffer'
      }, (resp) => {
        this.downloadFile(resp.data, originalFileName, contentType)
      }, function (err) {
        console.log(err)
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
      })
    },

    /**
     * starts file download on the client
     *
     * @param blobParts
     * @param fileName
     * @param contentType
     */
    downloadFile(blobParts, fileName, contentType) {
      if (window?.cordova?.platformId.toLowerCase() === 'ios') {
        let folder = window.cordova.file.tempDirectory
        this.downloadFileMobile(fileName, blobParts, contentType, folder)
      } else if (window?.cordova?.platformId.toLowerCase() === 'android') {
        let folder = window.cordova.file.externalDataDirectory
        this.downloadFileMobile(fileName, blobParts, contentType, folder)
      } else {
        this.downloadFileWeb(fileName, blobParts, contentType)
      }
    },

    /**
     * This function trigger a download on web browsers.
     * @param originalFileName
     * @param blob
     * @param contentType
     */
    downloadFileWeb: function (originalFileName, blob, contentType) {
      let fileURL = URL.createObjectURL(new Blob([blob], {type: contentType}))
      let fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', originalFileName)
      document.body.appendChild(fileLink)
      fileLink.click();
      URL.revokeObjectURL(fileLink.href);
    },

    /**
     * This function uses arraybuffer response to create a file on mobile devices local file system.
     * @param fileName
     * @param blob
     * @param contentType
     * @param folder
     */
    downloadFileMobile: function (fileName, blob, contentType, folder) {
      let self = this

      window.resolveLocalFileSystemURL(folder, function (dirEntry) {
        dirEntry.getFile(fileName, {create: true, exclusive: false}, function (fileEntry) {
          if (window?.cordova?.platformId.toLowerCase() === 'ios') {
            self.writeFileIos(fileEntry, blob, contentType)
          } else {
            self.writeFileAndroid(fileEntry, blob)
          }
        }, function (err) {
          console.warn(err)
        })
      }, function (err) {
        console.warn(err)
        self.$root.bisatoast.error({message: self.$t('app.generic-error')})
      })
    },

    arrayBufferToBase64: function (buffer) {
      var binary = '';
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },

    /**
     * This function opens a new created file.
     * @param fileEntry
     * @param dataObj
     * @param contentType
     */
    writeFileIos: function (fileEntry, dataObj, contentType) {
      let delimiterIndex = contentType.indexOf(';')
      let type = delimiterIndex !== -1 ? contentType.substr(0, delimiterIndex).trim() : contentType.trim()

      fileEntry.createWriter(function (fileWriter) {
        fileWriter.onwriteend = function () {
          window?.cordova?.plugins.fileOpener2.open(fileEntry.nativeURL, type,
              {
                error: function (e) {
                  console.error('Error status: ' + e.status + ' - Error message: ' + e.message)
                },
                success: function () {
                  console.log('file opened successfully')
                }
              })
        }
        fileWriter.onerror = function (error) {
          console.log('Failed file write: ' + error)
        }
        fileWriter.write(dataObj)
      })

    },

    /**
     * This function creates a file and opens it on Android devices.
     * It uses the cordova-plugin-saf-mediastore plugin, which can be used to read and save files via the Storage Access Framework and Mediastore.
     * In addition, it sets the correct permissions for different Android versions.
     * @param fileEntry
     * @param dataObj
     */
    writeFileAndroid: function (fileEntry, dataObj) {
      window.cordova.plugins.safMediastore.writeFile({
        "data": this.arrayBufferToBase64(dataObj),
        "filename": fileEntry.name
      }).then((contentUri) => {
        window.cordova.plugins.safMediastore.openFile(contentUri).then(function () {
          console.log('file opened successfully')
        }).catch(function (e) {
          console.error('Error status: ' + e.status + ' - Error message: ' + e.message)
        })
      })
    },

    /**
     * adds a file id to the list of already downloaded files
     */
    addDownloadedMarker(id) {
      let ids = JSON.parse(localStorage.getItem("downloaded-files"))
      if (ids == null) {
        ids = [id]
      } else if (!ids.includes(id)) {
        ids.push(id)
      }
      localStorage.setItem("downloaded-files", JSON.stringify(ids))
    },

    /**
     * returns true if the file with the passed id was already downloaded on this client
     */
    alreadyDownloaded(id) {
      let ids = JSON.parse(localStorage.getItem("downloaded-files"))
      if (ids == null) {
        return false
      } else {
        return ids.includes(id)
      }
    }
  },

  mounted() {
    this.loading = true
    requestHelper.startDelayedRequest(
        () => this.getData(true),
        () => this.updateInterval()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/documents.scss';
</style>
