<template>
  <fullscreen-overlay-frame :title="$t('add-charging-station-dialog.title').toString()"
                            icon="control_point_duplicate"
                            color="primary"
                            centered
                            closable
                            @close="close">
    <template v-slot:content>
      <v-card v-if="hasOcppChargingStations"
              :disabled="!isSiteAdmin"
              class="mb-4"
              @click="addOcppChargingStation">
        <v-card-title class="pb-0">
          <v-icon class="material-icons-outlined" color="primary" left>ev_station</v-icon>
          <span class="primary--text font-weight-bold font-size-04"
                v-text="$t('add-charging-station-dialog.ocpp.add')"/>
          <v-spacer/>
          <v-icon color="primary" class="material-icons-outlined">
            chevron_right
          </v-icon>
        </v-card-title>
        <v-card-text class="pl-12 font-size-02"
          v-text="$t('add-charging-station-dialog.ocpp.info')"/>
      </v-card>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import config from "@/config/config.app.json";
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";

export default {
  name: 'AddChargingStationDialog',
  components: {
    FullscreenOverlayFrame
  },

  props: ['data'],

  computed: {
    isSiteAdmin() {
      return this.$rhAuth.isSiteAdmin()
    },
    hasOcppChargingStations() {
      return (/true/).test(config.hasOcppChargingStations)
    }
  },

  methods: {
    /**
     * open gateway dialog
     */
    addOcppChargingStation() {
      this.close()
      this.$root.bisadialog.toggle('addOcppChargePoint')
    },

    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('addChargingStation')
    }
  }
};
</script>

