<template>
  <entry-frame :title="$t('registration-email-page.title').toString()">
    <template v-slot:subheader>
      <div v-html="$t('registration-email-page.subtitle')"/>
    </template>

    <template v-slot:form>
      <v-form v-model="valid" ref="form">
        <v-text-field outlined
                      v-model="email"
                      :rules="emailRules"
                      required
                      :label="$t('login-page.e-mail.label')"/>

        <v-card>
          <v-card-text class="pt-4 pb-2">
            <span class="privacy hint font-size-03"
                  v-html="$t('registration-email-page.privacy')"/>
            <v-checkbox :rules="privacyRules">
              <span slot="label"
                    v-html="$t('registration-email-page.privacy.agree')"/>
            </v-checkbox>
          </v-card-text>
        </v-card>

        <v-card v-if="canSignupHubspotNewsletter">
          <v-card-text class="mt-5 pt-4 pb-2">
            <span class="privacy hint font-size-03"
                  v-html="$t('registration-email-page.newsletter')"/>
              <v-checkbox v-model="acceptHubspotNewsletter">
              <span slot="label"
                    v-html="$t('registration-email-page.newsletter.agree')"/>
              </v-checkbox>
          </v-card-text>
        </v-card>

        <v-btn depressed x-large block
               class="mt-8 font-weight-bold"
               color="primary"
               :loading="loading"
               :disabled="loading"
               v-html="$t('app.send')"
               @click="register"/>
        <v-btn depressed text block
               color="primary"
               class="mt-4"
               :loading="loading"
               :disabled="loading"
               v-html="$t('app.cancel')"
               to="registration"/>
      </v-form>
    </template>
  </entry-frame>
</template>

<script>
import EntryFrame from "@/templates/components/EntryFrame";
import config from "@/config/config.app.json";

export default {
  name: 'RegistrationEmail',

  components: {
    EntryFrame
  },

  data: () => ({
    loading: false,
    valid: false,
    email: '',
    emailSent: false,
    acceptHubspotNewsletter: false
  }),

  methods: {
    register: function () {
      if (this.$refs.form.validate()) {
        this.loading = true

        let requestData = {
          email: this.email
        }

        if(this.acceptHubspotNewsletter) {
          requestData.has_hubspot_newsletter = this.acceptHubspotNewsletter
        }

        this.$rhRequest.sendPost({
          endpoint: "registration/init-registration",
          data: requestData
        }, () => {
          this.$root.bisadialog.toggle('registrationCode', true)
          this.loading = false
        }, (e) => {
          if (e?.response?.status === 409 || e?.status === 409) {
            // e-mail is already registered
            this.$root.bisadialog.toggle('registrationMailError', true)
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error')})
          }
          this.loading = false
        })
      }
    }
  },

  computed: {
    emailRules() {
      return [
        v => !!v || this.$t('app.rules.required'),
        v => /.+@.+\..+/.test(v) || this.$t('app.rules.email-invalid')
      ]
    },
    privacyRules() {
      return [
        v => v === true || this.$t('registration-email-page.rules.privacy')
      ]
    },
    /**
     * returns true if the user can sign up to hupspot newsletter
     * @returns {boolean}
     */
    canSignupHubspotNewsletter() {
      return config.hasHubspotNewsletter === 'true';
    }
  }
};
</script>
