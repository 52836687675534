const connectorStates = Object.freeze({
  available: 'Available',
  charging: 'Charging',
  finishing: 'Finishing',
  preparing: 'Preparing',
  suspendedEV: 'SuspendedEV',
  suspendedEVSE: 'SuspendedEVSE',
  unavailable: 'Unavailable',
  reserved: 'Reserved',
  faulted: 'Faulted',
})

const measurands = Object.freeze({
  energyActiveImportRegister: 'Energy.Active.Import.Register',
  powerActiveImport: 'Power.Active.Import',
  currentImport: 'Current.Import'
})

export default {
  connectorStates, measurands
}
